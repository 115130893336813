import React from "react";
import PropTypes from "prop-types";

import MetaTags from "react-meta-tags";

import StandardLayout from "./StandardLayout";
import NewsLayout from "./News/NewsLayout";
import SearchListLayout from "./SearchListLayout";
import ComponentMissing from "../Debug/ComponentMissing";
import ScrollToTop from "../Atoms/ScrollToTop";

const { REACT_APP_SITE_TITLE, REACT_APP_PUBLIC_URL } = process.env;

const LayoutSwitcher = ({ page }) => {
  const getRobots = () => {
    let robots = page.page.robots.noindex === "1" ? "noindex," : "index,";
    robots += page.page.robots.nofollow === "1" ? "nofollow" : "follow";

    return robots;
  };

  const renderMetaTags = () => {
    return (
      <MetaTags>
        <title>{page.page.meta.title || page.page.title}</title>
        <meta name="description" content={page.page.meta.description} />
        <meta name="keywords" content={page.page.meta.keywords} />
        <meta
          name="author"
          content={`${page.page.editorial.author}, ${page.page.editorial.authorEmail}`}
        />
        <meta name="robots" content={getRobots()} />
        <meta name="canonical" content={page.page.meta.canonical.url} />

        {REACT_APP_SITE_TITLE && <meta property="og:site_name" content={REACT_APP_SITE_TITLE} />}

        {page.page.socialMedia.ogTitle ? (
          <meta property="og:title" content={page.page.socialMedia.ogTitle} />
        ) : (
          <meta property="og:title" content={page.page.title} />
        )}

        {page.page.socialMedia.ogDescripton ? (
          <meta property="og:description" content={page.page.socialMedia.ogDescripton} />
        ) : (
          <meta property="og:description" content={page.page.meta.description} />
        )}

        {page.page.socialMedia.ogImage?.[0] && (
          <meta property="og:image" content={page.page.socialMedia.ogImage?.[0].publicUrl} />
        )}

        <meta property="og:url" content={REACT_APP_PUBLIC_URL + page.page.navigation.slug} />

        <meta name="twitter:card" content={page.page.socialMedia.twitterCard} />

        {page.page.socialMedia.twitterTitle ? (
          <meta name="twitter:title" content={page.page.socialMedia.twitterTitle} />
        ) : (
          <meta name="twitter:title" content={page.page.title} />
        )}

        {page.page.socialMedia.twitterDescription ? (
          <meta name="twitter:description" content={page.page.socialMedia.twitterDescription} />
        ) : (
          <meta name="twitter:description" content={page.page.meta.description} />
        )}

        {page.page.socialMedia.twitterImage?.[0] && (
          <meta name="twitter:image" content={page.page.socialMedia.twitterImage?.[0].publicUrl} />
        )}

        <meta name="twitter:url" content={REACT_APP_PUBLIC_URL + page.page.navigation.slug} />
      </MetaTags>
    );
  };

  const renderBackToTop = () => {
    return page.page.appearance.back2top ? <ScrollToTop /> : null;
  };

  const switchLayout = () => {
    switch (page.page.appearance.layout) {
      case "standard":
        return <StandardLayout content={page.content} pageInfo={page.page} />;
      case "news-list":
        return <NewsLayout content={page.content} pageInfo={page.page} />;
      case "search-list":
        return <SearchListLayout content={page.content} pageInfo={page.page} />;
      default:
        return <ComponentMissing type="Layout" subType={page.page.appearance.layout} />;
    }
  };

  return (
    <>
      {renderMetaTags()}

      {switchLayout()}

      {renderBackToTop()}
    </>
  );
};

LayoutSwitcher.propTypes = {
  page: PropTypes.instanceOf(Object),
};

export default LayoutSwitcher;
