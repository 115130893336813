import React, { useCallback } from "react";
import PropTypes from "prop-types";

const Divider = ({ properties: { cols, alignment } }) => {
  const getClasses = useCallback(() => {
    let classes = "row";

    switch (alignment) {
      case "right":
        classes += " justify-content-end";
        break;
      case "center":
        classes += " justify-content-center";
        break;
      default:
        classes += " justify-content-start";
    }

    return classes;
  }, [alignment]);

  return (
    <div className="container divider standrad">
      <div className={getClasses()}>
        <div className={`col-md-${cols}`}>
          <hr />
        </div>
      </div>
    </div>
  );
};

Divider.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default Divider;
