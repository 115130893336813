import React from "react";
import PropTypes from "prop-types";

import StandardTimeline from "./StandardTimeline";
import AlternatingTimeline from "./AlternatingTimeline";
import CenteredTimeline from "./CenteredTimeline";
import SliderTimeline from "./SliderTimeline";
import ComponentMissing from "../../Debug/ComponentMissing";

const TimelineSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  headerLink,
  headerLinkType,
  secondaryLink,
  secondaryLinkType,
  subline,
  teaser,
  timeline,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    timeline,
    animation,
  };

  function switchTimeline() {
    switch (layout) {
      case "standard":
        return <StandardTimeline properties={properties} />;
      case "alternating1":
        return <AlternatingTimeline properties={properties} />;
      case "centered":
        return <CenteredTimeline properties={properties} />;
      case "alternating":
        return <SliderTimeline properties={properties} />;
      default:
        return <ComponentMissing type="Timeline" subType={layout} />;
    }
  }

  return switchTimeline();
};

TimelineSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  headerLinkType: PropTypes.string,
  secondaryLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  secondaryLinkType: PropTypes.string,
  subline: PropTypes.string,
  teaser: PropTypes.string,
  timeline: PropTypes.instanceOf(Array),
  animation: PropTypes.instanceOf(Object),
};

export default TimelineSwitcher;
