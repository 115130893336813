import React, { useState, useEffect, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import qs from "qs";
import Cookies from "js-cookie";
import TagManager from "react-gtm-module";

import TranslationContext from "../Context/Translation";
import LayoutSwitcher from "../Components/Layout/LayoutSwitcher";

import { postApi } from "../Utils/ApiUtils";
import { scrollToAnchor } from "../Utils/ElementUtils";
import { parseCookies } from "../Utils/FunctionalUtils";

import { REACT_APP_SEARCH } from "../Constants/APIConstants";

const SearchContainer = ({ location, history }) => {
  const { setTranslation } = useContext(TranslationContext);

  const [isPageLoaded, setPageLoaded] = useState(false);
  const [page, setPage] = useState(false);
  const fireGAPageview = useCallback(
    (usettingsId, gsettingsId, hasUsercentrics, hasCookiebot) => {
      const parsedCookies = parseCookies(Cookies.get("CookieConsent"));

      if (
        (hasUsercentrics && localStorage.getItem("uc_user_interaction")) ||
        (hasCookiebot && (parsedCookies.statistics || parsedCookies.marketing)) ||
        (!hasUsercentrics && !hasCookiebot)
      ) {
        const uidSetup = {};
        const gidSetup = {};
        if (usettingsId) {
          uidSetup.trackingId = usettingsId;
        }
        if (gsettingsId) {
          gidSetup.trackingId = gsettingsId;
        }
        ReactGA.initialize([{ ...uidSetup }, { ...gidSetup }]);
        ReactGA.send({
          hitType: "pageview",
          page: location.pathname + location.search,
        });
      }
    },
    [location.pathname, location.search],
  );

  const fireGTagManager = useCallback((settingsId, hasUsercentrics, hasCookiebot) => {
    const parsedCookies = parseCookies(Cookies.get("CookieConsent"));

    // to solve script duplicating
    if (!window.GTMinitialized) {
      if (
        (hasUsercentrics && localStorage.getItem("uc_user_interaction")) ||
        (hasCookiebot && (parsedCookies.statistics || parsedCookies.marketing)) ||
        (!hasUsercentrics && !hasCookiebot)
      ) {
        const { REACT_APP_GOOGLE_TAG_MANAGER_ID } = process.env;

        if (settingsId || REACT_APP_GOOGLE_TAG_MANAGER_ID) {
          const tagManagerArgs = {
            gtmId: settingsId || REACT_APP_GOOGLE_TAG_MANAGER_ID,
          };

          TagManager.initialize(tagManagerArgs);

          window.GTMinitialized = true;
        }
      }
    }
  }, []);

  const fireUsercentrics = useCallback((settingsId) => {
    if (!document.getElementById("usercentrics-cmp")) {
      const scriptTag = document.createElement("script");
      scriptTag.id = "usercentrics-cmp";
      scriptTag.setAttribute("data-settings-id", settingsId);
      scriptTag.src = "https://app.usercentrics.eu/browser-ui/latest/bundle.js";
      scriptTag.async = true;
      document.body.appendChild(scriptTag);

      // fix for overflow: hidden during static website generation
      document.addEventListener("DOMContentLoaded", function () {
        if (document.body.style.getPropertyValue("overflow") === "hidden") {
          document.body.style.removeProperty("overflow");
        }
      });
    }
  }, []);

  const getData = useCallback(
    (data) => {
      // setup languages data in Context
      if (data.languages) {
        setTranslation(data.languages);
      }

      if (data.page.plugins?.google_analytics.trackingId) {
        fireGAPageview(
          null,
          data.page.plugins?.google_analytics?.trackingId,
          data.page.plugins?.usercentrics?.settingsId ||
            document.getElementById("usercentrics-cmp"),
          data.page.plugins?.cookie_bot?.id || document.getElementById("Cookiebot"),
        );
      }

      if (data.page.plugins?.usercentrics.settingsId) {
        fireUsercentrics(data.page.plugins.usercentrics.settingsId);
      }

      // if (data.page.plugins?.google.tag_manager) {
      fireGTagManager(
        null,
        data.page.plugins?.usercentrics?.settingsId || document.getElementById("usercentrics-cmp"),
        data.page.plugins?.cookie_bot?.id || document.getElementById("Cookiebot"),
      );
      // }

      setPage(data);
      setPageLoaded(true);

      scrollToAnchor();
    },
    [setTranslation, fireGAPageview, fireGTagManager, fireUsercentrics],
  );

  const loadPage = useCallback(async () => {
    // have to have a fallback when loading the search page directly
    const url = location.state?.searchListUrl || REACT_APP_SEARCH;

    // hide mobile menu
    const navToggler = document.querySelector(".navbar-toggler");
    const navCollapsed = document.querySelector(".navbar-collapse.show");

    if (navToggler && navCollapsed) {
      navToggler.click();

      document.body.classList.remove("overflow-hidden");
    }

    // hide dropdowns to prevent stuch on page changes
    const navDropdowns = document.querySelectorAll(".nav-item.dropdown .dropdown-menu");

    navDropdowns.forEach((dropdown) => {
      if (dropdown.classList.contains("show")) {
        dropdown.classList.remove("show");
      }
    });

    try {
      // have to have a fallback when loading the search page directly
      // location.search is a string with ?query=... value, have to take the value from it
      const searchValue = location.state?.searchValue || location.search.split("=")[1];

      const { data } = await postApi(url, qs.stringify({ search: searchValue }));

      if (data) {
        getData(data);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        const { data } = error.response;
        if (data) {
          getData(data);
          return;
        }
      }
      setPageLoaded(false);
      history.replace("/error");
    }
  }, [location.state, location.search, history, getData]);

  useEffect(() => {
    // for some reason anchor links for the same page when using react-router-dom links doesn't work
    // so to make it work we have to use the same scrollIntoView() approach that we use above

    scrollToAnchor();
    // }, [location.hash]);
  });

  // need to mimic the logic for plugins to make one-page websites work
  useEffect(() => {
    if (page) {
      if (
        page.page.plugins?.google_analytics?.trackingId ||
        page.page.plugins?.google_analytics?.ga4
      ) {
        fireGAPageview(
          null,
          page.page.plugins?.google_analytics?.trackingId,
          // page.page.plugins.google_analytics.ga4,
          page.page.plugins?.usercentrics?.settingsId ||
            document.getElementById("usercentrics-cmp"),
          page.page.plugins?.cookie_bot?.id || document.getElementById("Cookiebot"),
        );
      }

      // if (page.page.plugins?.usercentrics.settingsId) {
      //   fireUsercentrics(page.page.plugins.usercentrics.settingsId);
      // }

      // if (page.page.plugins?.google?.tag_manager) {
      fireGTagManager(
        page.page.plugins?.google?.tag_manager,
        page.page.plugins?.usercentrics?.settingsId || document.getElementById("usercentrics-cmp"),
        page.page.plugins?.cookie_bot?.id || document.getElementById("Cookiebot"),
      );
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, location.hash, fireGAPageview, fireUsercentrics, fireGTagManager]);

  useEffect(() => {
    if (!isPageLoaded) {
      loadPage();
    }

    return () => {
      // scroll top instantly after isPageLoaded has changed and before the code inside is called
      window.scrollTo({ top: 0, left: 0 });
    };
  }, [isPageLoaded, loadPage]);

  const renderLayout = () => {
    if (!isPageLoaded) {
      return (
        <div className="spinner">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "7.5rem", height: "7.5rem", borderWidth: "0.5em" }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      );
    }

    if (isPageLoaded && page) {
      return <LayoutSwitcher page={page} />;
    }

    return <></>;
  };

  return renderLayout();
};

SearchContainer.propTypes = {
  location: PropTypes.instanceOf(Object),
};

export default SearchContainer;
