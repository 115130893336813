/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
// import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

import { isMobile } from "../../../Utils/FunctionalUtils";

/**
 * full size component
 *
 * @returns {JSX.Element}
 * @constructor
 */
const GoogleMapComponent = withScriptjs(
  withGoogleMap(
    ({
      styleOptions,
      properties: { markers, icon, centerLat, centerLng, defaultZoom, animation },
      setActiveInfo,
    }) => {
      const [currentZoom, setCurrentZoom] = useState(6);

      useEffect(() => {
        if (!animation.googleMap.default || isMobile()) {
          setCurrentZoom(+defaultZoom);
        } else {
          const revealHandler = () => {
            setCurrentZoom(+defaultZoom);
          };

          document.addEventListener("aos:in:google-map", revealHandler);
        }
      }, [animation, defaultZoom, setCurrentZoom]);

      let defaultCenter = { lat: -34.397, lng: 150.644 };

      if (centerLat && centerLng) {
        defaultCenter = { lat: +centerLat, lng: +centerLng };
      }

      let markerIcon = null;

      if (icon) {
        markerIcon = { url: icon.publicUrl };
      }

      const renderMarkers = () => {
        return markers.map((marker) => {
          return (
            <Marker
              key={`key_${marker.latitude}_${marker.longitude}`}
              icon={markerIcon}
              position={{ lat: +marker.latitude, lng: +marker.longitude }}
              onClick={() => setActiveInfo(marker)}
            />
          );
        });
      };

      return (
        <GoogleMap
          defaultZoom={+defaultZoom}
          defaultCenter={defaultCenter}
          defaultOptions={{ styles: styleOptions }}
          zoom={currentZoom}
        >
          {renderMarkers()}
        </GoogleMap>
      );
    },
  ),
);

const SecondaryGoogleMaps = (props) => {
  const [activeInfo, setActiveInfo] = useState(
    props.properties.markers.length ? props.properties.markers[0] : {},
  );

  return (
    <div
      className="google-maps secondary"
      data-aos-anchor-placement="center-center"
      data-aos="none"
      data-aos-id="google-map"
    >
      <div className="row">
        <div className="col-12 col-md-6 mb-small mb-md-0">
          <div>
            <GoogleMapComponent {...props} setActiveInfo={setActiveInfo} />
          </div>
        </div>
        <div className="col-12 col-md-6 mb-small mb-md-0 d-flex">
          <div className="google-maps-info">
            <Headline headerType="h5" headline={activeInfo.header} />

            {activeInfo.info && (
              <div className="mt-xsmall">
                <Text textType="html" text={activeInfo.info} />
              </div>
            )}

            {activeInfo.link && (
              <div className="mt-xsmall">
                <a href={activeInfo.link.url} className="link-primary">
                  {activeInfo.link.title}
                </a>
                {/* <LinkSwitcher linkType="link-primary" link={activeInfo.link} /> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondaryGoogleMaps;
