import React from "react";
import PropTypes from "prop-types";

import StandardFactGrid from "./StandardFactGrid";
import HorizontalFactGrid from "./HorizontalFactGrid";
import ComponentMissing from "../../Debug/ComponentMissing";

const FactGridSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  subline,
  teaser,
  grid,
  columnsPerRow,
  cols,
  alignment,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    teaser,
    grid,
    columnsPerRow,
    cols,
    alignment,
    animation,
  };

  function switchFactGrid() {
    switch (layout) {
      case "standard":
        return <StandardFactGrid properties={properties} />;
      case "horizontal":
        return <HorizontalFactGrid properties={properties} />;
      default:
        return <ComponentMissing type="FactGrid" subType={layout} />;
    }
  }

  return switchFactGrid();
};

FactGridSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  subline: PropTypes.string,
  teaser: PropTypes.string,
  grid: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string]),
  columnsPerRow: PropTypes.instanceOf(Object),
  cols: PropTypes.string,
  alignment: PropTypes.string,
  animation: PropTypes.instanceOf(Object),
};

export default FactGridSwitcher;
