import React, { useCallback } from "react";
import PropTypes from "prop-types";

import MenuSectionSwitcher from "../Navigation/MenuSection/MenuSectionSwitcher";
import DividerSwitcher from "./Divider/DividerSwitcher";
import CustomCodeSwitcher from "./CustomCode/CustomCodeSwitcher";
import HeaderSwitcher from "./Header/HeaderSwitcher";
import TextSwitcher from "./Text/TextSwitcher";
import OnlyImageSwitcher from "./OnlyImage/OnlyImageSwitcher";
import CTASwitcher from "./CTA/CTASwitcher";
import AccordionSwitcher from "./Accordion/AccordionSwitcher";
import StageSwitcher from "./Stage/StageSwitcher";
import GallerySwitcher from "./Gallery/GallerySwitcher";
import DownloadsSwitcher from "./Downloads/DownloadsSwitcher";
// eslint-disable-next-line import/no-cycle
import NewsSwitcher from "./News/NewsSwitcher";
import FeaturesSwitcher from "./Features/FeaturesSwitcher";
import FactSheetSwitcher from "./FactSheet/FactSheetSwitcher";
import ProductCardsSwitcher from "./ProductCards/ProductCardsSwitcher";
import TeaserAndChartSwitcher from "./TeaserAndChart/TeaserAndChartSwitcher";
import TeaserAndImageSwitcher from "./TeaserAndImage/TeaserAndImageSwitcher";
import TextAndBulletsSwitcher from "./TextAndBullets/TextAndBulletsSwitcher";
import TextAndIconListSwitcher from "./TextAndIconList/TextAndIconListSwitcher";
import ComponentMissing from "../Debug/ComponentMissing";
import FormFrameworkSwitcher from "./FormFramework/FormFrameworkSwitcher";
import GoogleMapsSwitcher from "./GoogleMaps/GoogleMapsSwitcher";
import TableSwitcher from "./Table/TableSwitcher";
import WorldMapSwitcher from "./WorldMap/WorldMapSwitcher";
import InvestmentCalculatorSwitcher from "./InvestmentCalculator/InvestmentCalculatorSwitcher";
import InvestmentQuizSwitcher from "./InvestmentQuiz/InvestmentQuizSwitcher";
import PerformanceChartSwitcher from "./PerformanceChart/PerformanceChartSwitcher";
import ContactCardSwitcher from "./ContactCard/ContactCardSwitcher";
import QuotesSwitcher from "./Quotes/QuotesSwitcher";
import VideoSwitcher from "./Video/VideoSwitcher";
import LineChartsSwitcher from "./LineCharts/LineChartsSwitcher";
import SearchListSwitcher from "./SearchList/SearchListSwitcher";
import ComparisonGallerySwitcher from "./ComparisonGallery/ComparisonGallerySwitcher";
import ContactGallerySwitcher from "./ContactGallery/ContactGallerySwitcher";
import BreadcrumbsSwitcher from "./Breadcrumbs/BreadcrumbsSwitcher";
import AccordionGallerySwitcher from "./AccordionGallery/AccordionGallerySwitcher";
import GoogleMapsAndContactsSwitcher from "./GoogleMapsAndContacts/GoogleMapsAndContactsSwitcher";
import FactsAndBulletsSwitcher from "./FactsAndBullets/FactsAndBulletsSwitcher";
import TextAndContactSwitcher from "./TextAndContact/TextAndContactSwitcher";
import TextAndNumbersSwitcher from "./TextAndNumbers/TextAndNumbersSwitcher";
import InformationSliderSwitcher from "./InformationSlider/InformationSliderSwitcher";
import QuoteAndReferenceSwitcher from "./QuoteAndReference/QuoteAndReferenceSwitcher";
import TimelineSwitcher from "./Timeline/TimelineSwitcher";
// eslint-disable-next-line import/no-cycle
import TabsSwitcher from "./Tabs/TabsSwitcher";
// eslint-disable-next-line import/no-cycle, max-len
import AccordionAndCategoriesSwitcher from "./AccordionAndCategories/AccordionAndCategoriesSwitcher";
import LoginModalSwitcher from "./LoginModal/LoginModalSwitcher";
import SidebarAdSwitcher from "./SidebarAd/SidebarAdSwitcher";
import FactGridSwitcher from "./FactGrid/FactGridSwitcher";
import GridGallerySwitcher from "./GridGallery/GridGallerySwitcher";
// eslint-disable-next-line import/no-cycle
import TransitionContainerSwitcher from "./TransitionContainer/TransitionContainerSwitcher";

import { generateId } from "../../Utils/FunctionalUtils";
import { getAnimation } from "../../Utils/ElementUtils";

const ElementSwitcher = ({ contentElements, breadcrumbs, sidebar, isTabCycle = false }) => {
  const getClasses = useCallback((item) => {
    let classes = item.appearance.frameClass;
    classes += item.appearance.bgColorClass.length ? ` ${item.appearance.bgColorClass}` : "";
    classes += item.appearance.spaceBefore.length ? ` ${item.appearance.spaceBefore}` : "";
    classes += item.appearance.spaceAfter.length ? ` ${item.appearance.spaceAfter}` : "";
    classes += item.appearance.centerheads === "1" ? " centered" : "";
    classes += [
      "ahoii_toolkit_stage",
      "ahoii_toolkit_only_image",
      "ahoii_toolkit_teaser_and_image",
    ].includes(item.type)
      ? " position-relative"
      : "";
    // ABOVE - differentiate "cover" and "normal" display for images?
    classes +=
      ["menu_section"].includes(item.type) && item.appearance.layout === "onPage"
        ? // ? " shadow-lg"
          ""
        : "";
    classes +=
      ["ahoii_toolkit_stage"].includes(item.type) && item.appearance.layout === "video"
        ? " overflow-hidden"
        : "";
    classes += ["ahoii_toolkit_grid_gallery"].includes(item.type) ? " px-0" : "";
    classes += ["ahoii_toolkit_transition_container"].includes(item.type) ? " p-0" : "";

    return classes;
  }, []);

  const extractGroupedContent = useCallback(
    (groupIds, propertyName = "tabId") => {
      const ids = groupIds.map((item) => {
        return item.tabId;
      });

      const elements = [];

      contentElements.forEach((item) => {
        if (ids.includes(item[propertyName])) {
          elements.push(item);
        }
      });

      return elements;
    },
    [contentElements],
  );

  // useEffect(() => {
  //   // Setup isScrolling variable
  //   let isScrolling;

  //   const stickyTriggers = document.querySelectorAll(".sticky-trigger");

  //   // Listen for scroll events
  //   window.addEventListener(
  //     "scroll",
  //     function () {
  //       // Clear our timeout throughout the scroll
  //       window.clearTimeout(isScrolling);

  //       // Set a timeout to run after scrolling ends
  //       isScrolling = setTimeout(function () {
  //         // Run the callback
  //         const currentStickyTriggerIndex = [...stickyTriggers].findIndex((el) => {
  //           return isInViewport(el, 50);
  //         });
  // eslint-disable-next-line max-len
  //         const navigationElement = document.getElementById("navigation").querySelector(".navbar");
  //         const navigationHeight = navigationElement.offsetHeight;

  //         if (stickyTriggers[currentStickyTriggerIndex]) {
  //           const elementOffset = stickyTriggers[currentStickyTriggerIndex].offsetTop;

  //           console.log(stickyTriggers[currentStickyTriggerIndex]);
  // eslint-disable-next-line max-len
  //           window.scroll({ top: elementOffset - navigationHeight, left: 0, behavior: "smooth" });
  //         } else if (stickyTriggers[currentStickyTriggerIndex - 1]) {
  //           const elementOffset = stickyTriggers[currentStickyTriggerIndex - 1].offsetTop;

  // eslint-disable-next-line max-len
  //           window.scroll({ top: elementOffset - navigationHeight, left: 0, behavior: "smooth" });
  //         }

  //         return null;
  //       }, 250);
  //     },
  //     false,
  //   );
  // }, []);

  function switchContentElement(element) {
    switch (element.type) {
      case "menu_section":
        return (
          // menu_section as a content element on page should be used only with onPage layout
          <MenuSectionSwitcher
            properties={{
              layout: element.appearance.layout,
              navigation: element.content.menu,
            }}
          />
        );
      case "div":
        return (
          <DividerSwitcher
            layout={element.appearance.layout}
            cols={element.appearance.cols}
            alignment={element.appearance.alignment}
          />
        );
      case "html":
        return (
          <CustomCodeSwitcher layout={element.appearance.layout} code={element.content.bodytext} />
        );
      case "header":
        return (
          <HeaderSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            cols={element.appearance.cols}
            alignment={element.appearance.alignment}
            colProportions={element.appearance.colProportions}
            viewportHeight={element.appearance.viewportHeight}
            mirrored={element.appearance.mirrored}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "text":
        return (
          <TextSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            bodytext={element.content.bodytext}
            cols={element.appearance.cols}
            alignment={element.appearance.alignment}
            colProportions={element.appearance.colProportions}
            viewportHeight={element.appearance.viewportHeight}
            mirrored={element.appearance.mirrored}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_only_image":
        return (
          <OnlyImageSwitcher
            layout={element.appearance.layout}
            image={element.content.image[0]}
            maxHeight={element.appearance.maxheight}
            cols={element.appearance.cols}
            alignment={element.appearance.alignment}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_cta":
        return (
          <CTASwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_accordion":
        return (
          <AccordionSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            id={element.id}
            accordionItems={element.content.items}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_stage":
        return (
          <StageSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            media={element.content.media}
            logo={element.content.logo}
            maxHeight={element.appearance.maxheight}
            viewportHeight={element.appearance.viewportHeight}
            mirrored={element.appearance.mirrored}
            verticalAlignment={element.appearance.vertical_alignment}
            animation={getAnimation(
              element.appearance.animationLayout,
              element.appearance.mirrored,
            )}
          />
        );
      case "ahoii_toolkit_image_wall":
        return (
          <GallerySwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            subline={element.content.subheader}
            id={element.id}
            galleryItems={element.content.image}
            columnsPerRow={element.appearance.columnsPerRow}
            grayscale={element.appearance.grayscaling}
            lightbox={element.appearance.lightbox}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_downloads":
        return (
          <DownloadsSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            subline={element.content.subheader}
            downloads={element.content.media}
            columnsPerRow={element.appearance.columnsPerRow}
            cols={element.appearance.cols}
            alignment={element.appearance.alignment}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "news_pi1":
        return (
          <NewsSwitcher
            layout={element.appearance.layout}
            data={element.content.data}
            columnsPerRow={{
              col_xs: element.content.data.settings.PostsPerRowExtraSmall,
              col_sm: element.content.data.settings.PostsPerRowSmall,
              col_md: element.content.data.settings.PostsPerRowMedium,
              col_lg: element.content.data.settings.postsPerRow,
            }}
            sidebar={sidebar}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_features":
        return (
          <FeaturesSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            subline={element.content.subheader}
            id={element.id}
            features={element.content.items}
            columnsPerRow={element.appearance.columnsPerRow}
            grayscale={element.appearance.grayscaling}
            viewportHeight={element.appearance.viewportHeight}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_factsheet":
        return (
          <FactSheetSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            facts={element.content.facts}
            columnsPerRow={element.appearance.columnsPerRow}
            cols={element.appearance.cols}
            alignment={element.appearance.alignment}
            viewportHeight={element.appearance.viewportHeight}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_product_cards":
        return (
          <ProductCardsSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            subline={element.content.subheader}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            teaser={element.content.teaser}
            productCards={element.content.cards}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_teaser_and_charts":
        return (
          <TeaserAndChartSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            charts={element.content.charts}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_teaser_and_image":
        return (
          <TeaserAndImageSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            image={element.content.image[0]}
            backgroundImage={element.content.backgroundImage[0]}
            maxHeight={element.appearance.maxheight}
            viewportHeight={element.appearance.viewportHeight}
            mirrored={element.appearance.mirrored}
            fixedImage={element.appearance.fixedImage}
            animation={getAnimation(
              element.appearance.animationLayout,
              element.appearance.mirrored,
            )}
          />
        );
      case "ahoii_toolkit_text_and_bullets":
        return (
          <TextAndBulletsSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            bodytext={element.content.bodytext}
            mirrored={element.appearance.mirrored}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "tt_content_text_and_icon_list":
        return (
          <TextAndIconListSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            iconList={element.content.items}
            viewportHeight={element.appearance.viewportHeight}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_google_maps":
        return (
          <GoogleMapsSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            subline={element.content.subheader}
            mapStyle={element.content.mapStyle}
            icon={element.content.marker[0]}
            markers={element.content.items}
            centerLat={element.content.centerLat}
            centerLng={element.content.centerLng}
            defaultZoom={element.content.defaultZoom}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "form_formframework":
        return (
          <FormFrameworkSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            form={element.content.form}
            uplink={element.content.link}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "table":
        return (
          <TableSwitcher
            layout={element.content.tableHeaderPosition}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            teaser={element.content.tableCaption}
            table={element.content.bodytext}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_worldmap":
        return (
          <WorldMapSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            countries={element.content.list}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "easyfolio_investment_calculator":
        return (
          <InvestmentCalculatorSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            calculatorOptions={element.content.options}
          />
        );
      case "easyfolio_investment_quiz":
        return (
          <InvestmentQuizSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            steps={element.content.steps}
            evaluation={element.content.evaluation}
          />
        );
      case "easyfolio_performance_chart":
        return (
          <PerformanceChartSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            bottomTeaser={element.content.bottomTeaser}
            fonds={element.content.fonds}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_contact_card":
        return (
          <ContactCardSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            subline={element.content.subheader}
            image={element.content.image[0]}
            contactInfo={{
              contactName: element.content.contactName,
              contactPosition: element.content.contactPosition,
              contactLocation: element.content.contactLocation,
              contactMail: element.content.contactMail,
              contactPhone: element.content.contactPhone,
              contactDetails: element.content.contactdetail,
            }}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_quotes":
        return (
          <QuotesSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            subline={element.content.subheader}
            id={element.id}
            quoteItems={element.content.items}
            mirrored={element.appearance.mirrored}
            animation={getAnimation(
              element.appearance.animationLayout,
              element.appearance.mirrored,
            )}
          />
        );
      case "ahoii_toolkit_video":
        return (
          <VideoSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            subline={element.content.subheader}
            id={element.id}
            video={element.content.videolink}
            poster={element.content.preview_image[0]}
            cols={element.appearance.cols}
            alignment={element.appearance.alignment}
            autoplayOnVisible={element.content.autoplay}
          />
        );
      case "ahoii_toolkit_linecharts":
        return (
          <LineChartsSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            charts={element.content.graphs}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoiitoolkit_searchlist":
        return (
          <SearchListSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            data={element.content.data}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_comparison_gallery":
        return (
          <ComparisonGallerySwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            comparisonCards={element.content.cards}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_contact_gallery":
        return (
          <ContactGallerySwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            contactCards={element.content.contacts}
            columnsPerRow={element.appearance.columnsPerRow}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_breadcrumbs":
        return <BreadcrumbsSwitcher layout={element.appearance.layout} breadcrumbs={breadcrumbs} />;
      case "ahoii_toolkit_accordion_gallery":
        return (
          <AccordionGallerySwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            id={element.id}
            accordionCards={element.content.cards}
            columnsPerRow={element.appearance.columnsPerRow}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_google_maps_and_contact":
        return (
          <GoogleMapsAndContactsSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            subline={element.content.subheader}
            contactTitle={element.content.contactTitle}
            contactAddress={element.content.contactAddress}
            contactPhone={element.content.contactPhone}
            contactPhoneAlt={element.content.contactPhoneAlt}
            contactEmail={element.content.contactEmail}
            contactLink={element.content.contactLink}
            mirrored={element.appearance.mirrored}
            mapStyle={element.content.mapStyle}
            icon={element.content.items[0]}
            markers={element.content.items}
            centerLat={element.content.centerLat}
            centerLng={element.content.centerLng}
            defaultZoom={element.content.defaultZoom}
          />
        );
      case "ahoii_toolkit_facts_and_bulletlist":
        return (
          <FactsAndBulletsSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            facts={element.content.cards}
            columnsPerRow={element.appearance.columnsPerRow}
            cols={element.appearance.cols}
            alignment={element.appearance.alignment}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_text_and_contact":
        return (
          <TextAndContactSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            image={element.content.image[0]}
            companyName={element.content.companyName}
            contactName={element.content.contactName}
            contactPosition={element.content.contactPosition}
            mirrored={element.appearance.mirrored}
            animation={getAnimation(
              element.appearance.animationLayout,
              element.appearance.mirrored,
            )}
          />
        );
      case "ahoii_toolkit_text_and_numbers":
        return (
          <TextAndNumbersSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            cardHeader={element.content.cardHeader}
            numbers={element.content.numbers}
            mirrored={element.appearance.mirrored}
            animation={getAnimation(
              element.appearance.animationLayout,
              element.appearance.mirrored,
            )}
          />
        );
      case "ahoii_toolkit_information_slider":
        return (
          <InformationSliderSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            description={element.content.description}
            startValue={element.content.startValue}
            maxValue={element.content.maxValue}
            thresholds={element.content.thresholds}
            unitType={element.content.unitType}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_quote_and_reference":
        return (
          <QuoteAndReferenceSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            companyName={element.content.companyName}
            companyLogo={element.content.companyLogo[0]}
            customerName={element.content.customerName}
            customerPosition={element.content.customerPosition}
            customerImage={element.content.customerImage[0]}
            quote={element.content.quoteText}
            reference={element.content.aboutCompany}
            mirrored={element.appearance.mirrored}
            animation={getAnimation(
              element.appearance.animationLayout,
              element.appearance.mirrored,
            )}
          />
        );
      case "ahoii_toolkit_history":
        return (
          <TimelineSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            headerLink={element.content.headerLink}
            headerLinkType={element.content.headerLinkType}
            secondaryLink={element.content.secondaryLink}
            secondaryLinkType={element.content.secondaryLinkType}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            timeline={element.content.items}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_tabs":
        return (
          <TabsSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            subline={element.content.subheader}
            items={element.content.items}
            tabContent={extractGroupedContent(element.content.items, "tabId")}
          />
        );
      case "ahoii_toolkit_accordion_and_categories":
        return (
          <AccordionAndCategoriesSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            subline={element.content.subheader}
            items={element.content.items}
            tabContent={extractGroupedContent(element.content.items, "tabId")}
          />
        );
      case "ahoii_toolkit_login_modal":
        return (
          <LoginModalSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            formLink={element.content.posturl}
            descriptionLink={element.content.helpurl}
            descriptionBackground={element.content.media[0]}
            descriptionImage={element.content.logo[0]}
            descriptionHeader={element.content.description}
            descriptionText={element.content.teaser}
          />
        );
      case "ahoii_toolkit_sidebar_ad":
        return (
          <SidebarAdSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            image={element.content.image[0]}
          />
        );
      case "ahoii_toolkit_fact_grid":
        return (
          <FactGridSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            subline={element.content.subheader}
            teaser={element.content.teaser}
            grid={element.content.rows}
            columnsPerRow={element.appearance.columnsPerRow}
            cols={element.appearance.cols}
            alignment={element.appearance.alignment}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_grid_gallery":
        return (
          <GridGallerySwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            subline={element.content.subheader}
            id={element.id}
            galleryItems={element.content.cards}
            columnsPerRow={element.appearance.columnsPerRow}
            grayscale={element.appearance.grayscaling}
            lightbox={element.appearance.lightbox}
            animation={getAnimation(element.appearance.animationLayout)}
          />
        );
      case "ahoii_toolkit_transition_container":
        return (
          <TransitionContainerSwitcher
            layout={element.appearance.layout}
            header={element.content.header}
            headerType={element.content.headerLayout}
            headerDisplayStyle={element.content.headerDisplayStyle}
            subline={element.content.subheader}
            items={element.content.items}
            transitionContainerContent={extractGroupedContent(
              element.content.items,
              "transitionId",
            )}
          />
        );
      default:
        return <ComponentMissing type="ContentElement" subType={element.type} />;
    }
  }

  const renderContentElements = () => {
    return contentElements?.map((contentElement) => {
      // const elementId = `${contentElement.id}_${contentElement.type}`;
      const elementId = generateId(contentElement);

      if (contentElement.tabId !== "0" && !isTabCycle) {
        return null;
      }

      if (contentElement.transitionId !== "0" && !isTabCycle) {
        return null;
      }

      return (
        <>
          {/* <section className="sticky-trigger" /> */}

          <section
            key={`key_${contentElement.id || Math.random()}`}
            id={elementId}
            className={getClasses(contentElement)}
          >
            {switchContentElement(contentElement)}
          </section>
        </>
      );
    });
  };

  return renderContentElements();
};

ElementSwitcher.propTypes = {
  contentElements: PropTypes.instanceOf(Array),
  breadcrumbs: PropTypes.instanceOf(Array),
  sidebar: PropTypes.instanceOf(Array),
  isTabCycle: PropTypes.bool,
};

export default ElementSwitcher;
