import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const FullWidthStage = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    media,
    maxHeight,
    viewportHeight,
    verticalAlignment,
    animation,
  },
}) => {
  const inlineStyles = {
    position: "absolute",
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: "0",
  };

  const getClasses = useCallback(() => {
    let classes = "row";

    switch (verticalAlignment) {
      case "top":
        classes += " align-items-start";
        break;
      case "center":
        classes += " align-items-center";
        break;
      case "bottom":
        classes += " align-items-end";
        break;
      default:
        classes += " align-items-center";
    }

    return classes;
  }, [verticalAlignment]);

  return (
    <div
      className={`stage full-width ${
        viewportHeight ? "min-vh-100 pt-xlarge pb-xlarge" : "pt-xlarge pb-xlarge"
      }`.trim()}
      style={
        !viewportHeight ? { minHeight: +maxHeight || media[0]?.properties.dimensions.height } : {}
      }
    >
      <Image image={media[0]} style={inlineStyles} />

      <div className="container">
        <div className={getClasses()}>
          <div className="col-md-6">
            <Preline preline={subline} />

            <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />
          </div>

          <div className="col-md-6">
            <Text textType="html" text={teaser} />

            {(headerLink || secondaryLink) && (
              <div className="mt-small" data-aos={animation.links.default}>
                <div className="links">
                  <LinkSwitcher
                    linkType={headerLinkType}
                    link={headerLink}
                    // additionalClasses="btn-lg rounded-pill"
                  />

                  <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

FullWidthStage.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default FullWidthStage;
