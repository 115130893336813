import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const BannerHorizontalTAI = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    backgroundImage,
    maxHeight,
    viewportHeight,
    fixedImage,
    mirrored,
    animation,
  },
}) => {
  const inlineStyles = {
    position: "absolute",
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: "-1",
  };

  const getTextContainer = useCallback(
    (alignRight) => {
      return (
        <div className={`col-12 col-md-8 col-lg-9 ${alignRight ? "text-md-end" : ""}`.trim()}>
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="html" text={teaser} />
            </div>
          )}
        </div>
      );
    },
    [header, headerType, headerDisplayStyle, subline, teaser],
  );

  const getLinksContainer = useCallback(() => {
    return (
      <div className="col-12 col-md-4 col-lg-3 mt-4 mt-md-0">
        <div data-aos={animation.links.default}>
          <div className="links">
            <LinkSwitcher
              linkType={headerLinkType}
              link={headerLink}
              // additionalClasses="btn-lg rounded-pill"
            />

            <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
          </div>
        </div>
      </div>
    );
  }, [headerLink, headerLinkType, secondaryLink, secondaryLinkType, animation]);

  const getContent = useCallback(() => {
    if (mirrored && mirrored !== "0") {
      return (
        <div className="row flex-row-reverse align-items-center mirrored">
          {getTextContainer()}
          {getLinksContainer()}
        </div>
      );
    }

    return (
      <div className="row align-items-center">
        {getTextContainer(true)}
        {getLinksContainer()}
      </div>
    );
  }, [mirrored, getTextContainer, getLinksContainer]);

  return (
    <div
      className={`teaser-and-image banner-horizontal ${fixedImage ? "fixed" : ""} ${
        viewportHeight
          ? "min-vh-100 d-flex flex-column justify-content-center pt-xlarge pb-xlarge"
          : ""
      }`.trim()}
      style={{ minHeight: +maxHeight || backgroundImage?.properties.dimensions.height }}
    >
      <Image image={backgroundImage} style={inlineStyles} />

      <div className="container">{getContent()}</div>
    </div>
  );
};

BannerHorizontalTAI.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default BannerHorizontalTAI;
