import React from "react";
import PropTypes from "prop-types";

import StandardVideo from "./StandardVideo";
import ComponentMissing from "../../Debug/ComponentMissing";

const VideoSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  subline,
  id,
  video,
  poster,
  cols,
  alignment,
  autoplayOnVisible,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    id,
    video,
    poster,
    cols,
    alignment,
    autoplayOnVisible,
  };

  function switchVideo() {
    switch (layout) {
      case "standard":
        return <StandardVideo properties={properties} />;
      default:
        return <ComponentMissing type="Video" subType={layout} />;
    }
  }

  return switchVideo();
};

VideoSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  subline: PropTypes.string,
  id: PropTypes.number,
  video: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  poster: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  alignment: PropTypes.string,
  cols: PropTypes.string,
  autoplayOnVisible: PropTypes.bool,
};

export default VideoSwitcher;
