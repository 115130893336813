import React, { useRef, useCallback, useContext } from "react";
import PropTypes from "prop-types";

import SlickSlider from "react-slick";

import TranslationContext from "../../../Context/Translation";

import SliderNewsListItem from "./SliderNewsListItem";

const PrevArrow = (buttonProps) => {
  return (
    <span
      className={`carousel-control-prev-icon ${buttonProps.className}`}
      aria-hidden="true"
      onClick={buttonProps.onClick}
    />
  );
};

const NextArrow = (buttonProps) => {
  return (
    <span
      className={`carousel-control-next-icon ${buttonProps.className}`}
      aria-hidden="true"
      onClick={buttonProps.onClick}
    />
  );
};

/* eslint-disable react/jsx-props-no-spreading */
const SliderNewsList = ({ properties: { news, columnsPerRow, hideDate, animation } }) => {
  const sliderRef = useRef(null);

  const settings = useRef({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: +columnsPerRow.col_lg,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: +columnsPerRow.col_md,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: +columnsPerRow.col_sm,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: +columnsPerRow.col_xs,
          slidesToScroll: 1,
        },
      },
    ],
  });
  const { translation } = useContext(TranslationContext);

  const activeTranslation = translation.find((lang) => lang.active === 1);

  const renderNews = useCallback(() => {
    return news.map((newsItem) => {
      return (
        <SliderNewsListItem
          news={newsItem}
          key={`key_${newsItem.title || Math.random()}`}
          linkType={newsItem.linkType}
          linkTitle={
            newsItem.linkTitle ||
            (activeTranslation.twoLetterIsoCode === "de" ? "Weiterlesen" : "Read More")
          }
          hideDate={hideDate}
          animation={animation}
        />
      );
    });
  }, [news, activeTranslation, hideDate, animation]);

  return (
    <div className="container news-list slider">
      <div className="row">
        <div className="col-12">
          <div ref={sliderRef} id="news-list-slider">
            <SlickSlider {...settings.current}>{renderNews()}</SlickSlider>
          </div>
        </div>
      </div>
    </div>
  );
};

SliderNewsList.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default SliderNewsList;
