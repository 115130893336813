import React from "react";
import PropTypes from "prop-types";

const SingleSelect = ({
  id,
  label,
  formData,
  validationErrors,
  onChangeHandler,
  defaultValue,
  options,
  additionalClasses,
}) => {
  return (
    <div className={`col-12 ${additionalClasses}`.trim()}>
      <div className="form-floating">
        <select
          id={id}
          name={id}
          value={formData[id]}
          onChange={onChangeHandler}
          className={`form-select color-static ${validationErrors ? "is-invalid" : ""}`.trim()}
        >
          <option disabled>{defaultValue}</option>

          {Object.values(options).map((option) => {
            return (
              <option key={`key_${option || Math.random()}`} value={option}>
                {option}
              </option>
            );
          })}
        </select>

        {label && (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label
            htmlFor={id}
            className="form-label color-static"
            dangerouslySetInnerHTML={{ __html: label }}
          />
        )}
      </div>
    </div>
  );
};

SingleSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  formData: PropTypes.instanceOf(Object),
  validationErrors: PropTypes.string,
  onChangeHandler: PropTypes.func,
  defaultValue: PropTypes.string,
  options: PropTypes.instanceOf(Object),
  additionalClasses: PropTypes.string,
};

SingleSelect.defaultProps = {
  additionalClasses: "",
};

export default SingleSelect;
