import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const CardText = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    bodytext,
    cols,
    alignment,
    viewportHeight,
    animation,
  },
}) => {
  const getClasses = useCallback(() => {
    let classes = "row";

    switch (alignment) {
      case "right":
        classes += " justify-content-end";
        break;
      case "center":
        classes += " justify-content-center";
        break;
      default:
        classes += " justify-content-start";
    }

    return classes;
  }, [alignment]);

  return (
    <div
      className={`container text card-text ${
        viewportHeight
          ? "min-vh-100 d-flex flex-column justify-content-center pt-xlarge pb-xlarge"
          : ""
      }`.trim()}
    >
      <div className={getClasses()}>
        <div className={`col-md-${cols}`}>
          <div className="card">
            <div className="card-body">
              {subline && (
                <div data-aos={animation.preline.default}>
                  <Preline preline={subline} additionalClasses="color-static" />
                </div>
              )}

              {header && (
                <div data-aos={animation.headline.default}>
                  <Headline
                    headerType={headerType}
                    headerStyle={headerDisplayStyle}
                    headline={header}
                    additionalClasses="color-static"
                  />
                </div>
              )}

              {bodytext && (
                <div className="mt-xsmall" data-aos={animation.teaser.default}>
                  <Text textType="html" text={bodytext} additionalClasses="color-static" />
                </div>
              )}

              {(headerLink || secondaryLink) && (
                <div className="mt-small" data-aos={animation.links.default}>
                  <div className="links">
                    <LinkSwitcher
                      linkType={headerLinkType}
                      link={headerLink}
                      // additionalClasses="btn-lg rounded-pill"
                    />

                    <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CardText.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default CardText;
