import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

import Fancybox from "../../Atoms/FancyBox";

import { REACT_APP_API_BASE_URL } from "../../../Constants/APIConstants";

const WallGallery = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    id,
    galleryItems,
    columnsPerRow,
    lightbox,
    animation,
  },
}) => {
  const renderGallery = useCallback(() => {
    const { col_xs: colXs, col_sm: colSm, col_md: colMd, col_lg: colLg } = columnsPerRow;

    return (
      <div
        className={`row 
                    row-cols-${colXs} 
                    row-cols-sm-${colSm} 
                    row-cols-md-${colMd} 
                    row-cols-lg-${colLg} 
                    g-4`.replace(/\n\s+/g, "")}
      >
        {Object.values(galleryItems.columns).map((column) => {
          return (
            <div key={`key_${column.properties.title || Math.random()}`} className="col d-flex">
              <div className="card w-100">
                <div className="card-image">
                  {lightbox ? (
                    <Fancybox>
                      <a href={column.publicUrl} data-fancybox={id}>
                        {column.properties.videoThumbnail ? (
                          <Image
                            image={{
                              // eslint-disable-next-line max-len
                              publicUrl: `${REACT_APP_API_BASE_URL}/${column.properties.videoThumbnail}`,
                              properties: { alternative: column.properties.filename },
                            }}
                          />
                        ) : (
                          <Image image={column} />
                        )}
                      </a>
                    </Fancybox>
                  ) : (
                    <Image image={column} />
                  )}
                </div>

                <div className="card-body">
                  <Preline preline={column.properties.subline} additionalClasses="color-static" />

                  <Headline
                    headerType={column.properties.headerType}
                    headline={column.properties.title}
                    additionalClasses="color-static"
                  />

                  {column.properties.description && (
                    <div className="mt-xsmall">
                      <Text
                        textType="text"
                        text={column.properties.description}
                        additionalClasses="color-static"
                      />
                    </div>
                  )}

                  {column.properties.link && (
                    <div className="mt-small" data-aos={animation.links.default}>
                      <div className="links">
                        <LinkSwitcher
                          linkType={column.properties.linkType}
                          link={column.properties.link}
                          additionalClasses="color-static"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [galleryItems, columnsPerRow, lightbox, id, animation]);

  return (
    <div className="container gallery wall">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />
        </div>
      </div>

      {Object.values(galleryItems.columns).length > 0 && (
        <div className="row mt-small">
          <div className="col-12">{renderGallery()}</div>
        </div>
      )}
    </div>
  );
};

WallGallery.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default WallGallery;
