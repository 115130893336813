import React from "react";
import PropTypes from "prop-types";

import MediumTAI from "./MediumTAI";
import LargeTAI from "./LargeTAI";
import SingleSideTAI from "./SingleSideTAI";
import XlargeTAI from "./XlargeTAI";
import SmallTAI from "./SmallTAI";
import CardTAI from "./CardTAI";
import BannerTAI from "./BannerTAI";
import BannerHorizontalTAI from "./BannerHorizontalTAI";
import ShiftedTAI from "./ShiftedTAI";
import ComponentMissing from "../../Debug/ComponentMissing";

const TeaserAndImageSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  headerLink,
  headerLinkType,
  secondaryLink,
  secondaryLinkType,
  subline,
  teaser,
  image,
  backgroundImage,
  maxHeight,
  viewportHeight,
  mirrored,
  fixedImage,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    image,
    backgroundImage,
    maxHeight,
    viewportHeight,
    mirrored,
    fixedImage,
    animation,
  };

  function switchTeaserAndImage() {
    switch (layout) {
      case "medium":
        return <MediumTAI properties={properties} />;
      case "large":
        return <LargeTAI properties={properties} />;
      case "single-side":
        return <SingleSideTAI properties={properties} />;
      case "xlarge":
        return <XlargeTAI properties={properties} />;
      case "small":
        return <SmallTAI properties={properties} />;
      case "card":
        return <CardTAI properties={properties} />;
      case "banner":
        return <BannerTAI properties={properties} />;
      case "banner-horizontal":
        return <BannerHorizontalTAI properties={properties} />;
      case "shifted":
        return <ShiftedTAI properties={properties} />;
      default:
        return <ComponentMissing type="TeaserAndImage" subType={layout} />;
    }
  }

  return switchTeaserAndImage();
};

TeaserAndImageSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  headerLinkType: PropTypes.string,
  secondaryLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  secondaryLinkType: PropTypes.string,
  subline: PropTypes.string,
  teaser: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  backgroundImage: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  maxHeight: PropTypes.string,
  viewportHeight: PropTypes.bool,
  mirrored: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fixedImage: PropTypes.bool,
  animation: PropTypes.instanceOf(Object),
};

export default TeaserAndImageSwitcher;
