import React from "react";
import PropTypes from "prop-types";

import StandardOnlyImage from "./StandardOnlyImage";
import FullWidthOnlyImage from "./FullWidthOnlyImage";
import ComponentMissing from "../../Debug/ComponentMissing";

const OnlyImageSwitcher = ({ layout, image, maxHeight, cols, alignment, animation }) => {
  const properties = {
    image,
    maxHeight,
    cols,
    alignment,
    animation,
  };

  function switchOnlyImage() {
    switch (layout) {
      case "standard":
        return <StandardOnlyImage properties={properties} />;
      case "full-width":
        return <FullWidthOnlyImage properties={properties} />;
      default:
        return <ComponentMissing type="OnlyImage" subType={layout} />;
    }
  }

  return switchOnlyImage();
};

OnlyImageSwitcher.propTypes = {
  layout: PropTypes.string,
  image: PropTypes.instanceOf(Object),
  maxHeight: PropTypes.string,
  alignment: PropTypes.string,
  cols: PropTypes.string,
  animation: PropTypes.instanceOf(Object),
};

export default OnlyImageSwitcher;
