import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const WallFeatures = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    features,
    viewportHeight,
    animation,
  },
}) => {
  const renderFeatures = useCallback(() => {
    return features.map((feature) => {
      const { cols_xs: colXs, cols_sm: colSm, cols_md: colMd, cols_lg: colLg } = feature;

      let featureItem = (
        <div className="card w-100">
          <div className="card-body">
            <div className="card-image">
              {/* <Image image={feature.image[0]} animation={animation.image.default} /> */}
              <Image image={feature.image[0]} />
            </div>

            <div className={`${feature.image[0] ? "mt-xsmall" : ""}`.trim()}>
              <Preline preline={feature.subheader} additionalClasses="color-static" />

              <Headline
                headerType="h5"
                headline={feature.headline}
                additionalClasses="color-static"
              />
            </div>

            {feature.teaser && (
              <div className="mt-xsmall">
                <Text textType="html" text={feature.teaser} additionalClasses="color-static" />
              </div>
            )}

            {feature.link && (
              // <div className="mt-small" data-aos={animation.links.default}>
              <div className="mt-small">
                <div className="links">
                  <LinkSwitcher
                    linkType={feature.link_type}
                    link={feature.link}
                    additionalClasses="color-static"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      );

      if (feature.cardLink) {
        featureItem = (
          <a
            href={feature.cardLink.url}
            target={feature.cardLink.target}
            rel="noreferrer"
            className="d-flex card-link w-100"
          >
            <div className="card w-100">
              <div className="card-body">
                <div className="card-image">
                  {/* <Image image={feature.image[0]} animation={animation.image.default} /> */}
                  <Image image={feature.image[0]} />
                </div>

                <div className={`${feature.image[0] ? "mt-xsmall" : ""}`.trim()}>
                  <Preline preline={feature.subheader} additionalClasses="color-static" />

                  <Headline
                    headerType="h5"
                    headline={feature.headline}
                    additionalClasses="color-static"
                  />
                </div>

                {feature.teaser && (
                  <div className="mt-xsmall">
                    <Text textType="html" text={feature.teaser} additionalClasses="color-static" />
                  </div>
                )}
              </div>
            </div>
          </a>
        );
      }

      return (
        <div
          key={`key_${feature.headline || Math.random()}`}
          className={`col-xs-${colXs} col-sm-${colSm} col-md-${colMd} col-lg-${colLg} d-flex`}
          data-aos={animation.features.default}
        >
          {featureItem}
        </div>
      );
    });
  }, [features, animation]);

  return (
    <div
      className={`container features wall ${
        viewportHeight
          ? "min-vh-100 d-flex flex-column justify-content-center pt-small pb-small"
          : ""
      }`.trim()}
    >
      <div className="row justify-content-center">
        <div className="col-md-10">
          {subline && (
            <div data-aos={animation.preline.default}>
              <Preline preline={subline} />
            </div>
          )}

          {header && (
            <div data-aos={animation.headline.default}>
              <Headline
                headerType={headerType}
                headerStyle={headerDisplayStyle}
                headline={header}
              />
            </div>
          )}
        </div>
      </div>

      {features.length > 0 && (
        <div className="mt-small">
          <div className="row justify-content-center g-4">{renderFeatures()}</div>
        </div>
      )}
    </div>
  );
};

WallFeatures.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default WallFeatures;
