import React from "react";
import PropTypes from "prop-types";

import WallGridGallery from "./WallGridGallery";
import ComponentMissing from "../../Debug/ComponentMissing";

const GridGallery = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  subline,
  id,
  galleryItems,
  columnsPerRow,
  grayscale,
  lightbox,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    id,
    galleryItems,
    columnsPerRow,
    grayscale,
    lightbox,
    animation,
  };

  function switchGridGallery() {
    switch (layout) {
      case "wall":
        return <WallGridGallery properties={properties} />;
      default:
        return <ComponentMissing type="Grid Gallery" subType={layout} />;
    }
  }

  return switchGridGallery();
};

GridGallery.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  subline: PropTypes.string,
  id: PropTypes.number,
  items: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  columnsPerRow: PropTypes.instanceOf(Object),
  grayscale: PropTypes.bool,
  lightbox: PropTypes.bool,
  animation: PropTypes.instanceOf(Object),
};

export default GridGallery;
