import React from "react";
import PropTypes from "prop-types";

import StandardMenuPagesFullWidth from "./StandardMenuPagesFullWidth";
import ComponentMissing from "../../Debug/ComponentMissing";

const MenuPagesFullWidthSwitcher = ({
  properties: {
    layout,
    primaryLink,
    primaryLinkType,
    secondaryLink,
    secondaryLinkType,
    logo,
    topLinks,
    navigation,
    languages,
    activeLanguage,
  },
}) => {
  const properties = {
    primaryLink,
    primaryLinkType,
    secondaryLink,
    secondaryLinkType,
    logo,
    topLinks,
    navigation,
    languages,
    activeLanguage,
  };

  function switchMenuPagesFullWidth() {
    switch (layout) {
      case "standard":
        return <StandardMenuPagesFullWidth properties={properties} />;
      default:
        return <ComponentMissing type="MenuPagesFullWidth" subType={layout} />;
    }
  }

  return switchMenuPagesFullWidth();
};

MenuPagesFullWidthSwitcher.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default MenuPagesFullWidthSwitcher;
