import React, { useCallback } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import { ReactComponent as InternalLinkIconRight } from "../../../Images/svg/chevron-right.svg";
import { ReactComponent as InternalLinkIconLeft } from "../../../Images/svg/chevron-left.svg";
// import { ReactComponent as ExternalLinkIcon } from "../../../Images/svg/arrow-up.svg";

const StandardLink = ({ properties: { linkType, link, additionalClasses, onClick, mirrored } }) => {
  const getClasses = useCallback(() => {
    let appearance = "link";

    switch (linkType) {
      case "link-primary":
        appearance += " link-primary";
        break;
      case "link-secondary":
        appearance += " link-secondary";
        break;
      case "link-light":
        appearance += " link-light";
        break;
      default:
        appearance += "";
    }

    if (mirrored) {
      appearance += " mirrored";
    }

    return appearance;
  }, [linkType, mirrored]);

  const getStandardLink = useCallback(() => {
    if (link.publicUrl?.includes("http") || link.url?.includes("http")) {
      if (link.publicUrl?.includes("fileadmin") || link.url?.includes("fileadmin")) {
        return (
          <a
            href={
              link.properties?.originalUrl ||
              link.publicUrl?.split("/")[1] ||
              link.url?.split("/")[1]
            }
            target={link.target}
            data-sectionid={link.sectionId}
            data-isin={link.isin}
            className={`${getClasses()} ${additionalClasses}`.trim()}
            onClick={onClick}
          >
            {link.title}

            {mirrored ? <InternalLinkIconLeft /> : <InternalLinkIconRight />}
          </a>
        );
      }

      return (
        <a
          href={link.publicUrl || link.url}
          target={link.target}
          data-sectionid={link.sectionId}
          data-isin={link.isin}
          className={`${getClasses()} ${additionalClasses}`.trim()}
          onClick={onClick}
        >
          {link.title}

          {/* <ExternalLinkIcon /> */}
          {mirrored ? <InternalLinkIconLeft /> : <InternalLinkIconRight />}
        </a>
      );
    }

    return (
      <NavLink
        to={link.publicUrl || link.url}
        target={link.target}
        data-sectionid={link.sectionId}
        data-isin={link.isin}
        className={`${getClasses()} ${additionalClasses}`.trim()}
        activeClassName=""
        onClick={onClick}
        // aria-current="false"
      >
        {link.title}

        {mirrored ? <InternalLinkIconLeft /> : <InternalLinkIconRight />}
      </NavLink>
    );
  }, [link, additionalClasses, getClasses, onClick, mirrored]);

  return link && (link.publicUrl || link.url) ? getStandardLink() : <></>;
};

StandardLink.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardLink;
