/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import PropTypes from "prop-types";

import DividerSwitcher from "../ContentElements/Divider/DividerSwitcher";
import Image from "../Atoms/Image";
import Text from "../Atoms/Text";
import Headline from "../Atoms/Headline";

import { ReactComponent as LocationIcon } from "../../Images/svg/location.svg";
import { ReactComponent as PhoneIcon } from "../../Images/svg/phone.svg";
import LinkSwitcher from "../Atoms/Link/LinkSwitcher";

const BlockFooter = ({ content }) => {
  const colspan = 12 / content.blockItems.length;

  const renderContactInfo = () => {
    return (
      <div className="footer-contact-info">
        <ul className="footer-contact-info-list">
          {content.address && (
            <li className="footer-contact-info-item">
              <div className="footer-contact-info-icon">
                <LocationIcon />
              </div>
              <a
                href={`https://maps.google.com/?q=${content.address}`}
                target="_blank"
                rel="noreferrer"
                className="footer-contact-info-link"
                dangerouslySetInnerHTML={{ __html: content.address }}
              />
            </li>
          )}

          {content.phonenumber && (
            <li className="footer-contact-info-item">
              <div className="footer-contact-info-icon">
                <PhoneIcon />
              </div>
              <a
                href={`tel:${content.phonenumber.replace(/\D/g, "")}`}
                className="footer-contact-info-link"
              >
                {content.phonenumber}
              </a>
            </li>
          )}
        </ul>

        {content.headerLink && (
          <div className="footer-contact-info-button">
            <LinkSwitcher link={content.headerLink} linkType={content.headerLinkType} />
          </div>
        )}
      </div>
    );
  };

  const renderSocialLinks = () => {
    return content.socialMedia.links?.map((socialMediaLink) => {
      return (
        <a
          key={`key_${socialMediaLink.link.url || Math.random()}`}
          href={socialMediaLink.link.url}
          noopener="true"
          noreferrer="true"
          className="link link-inline"
          target="_blank"
          rel="noreferrer"
        >
          <i className={`fab fa-${socialMediaLink.className || socialMediaLink.type}`} />
        </a>
      );
    });
  };

  function renderBlockItem(item, index) {
    return (
      <div
        className={`col-lg-6 col-xl-${colspan} ${
          index !== content.blockItems.length - 1 ? "mb-5 mb-xl-0" : ""
        }`.trim()}
        key={`key_${item.header || Math.random()}`}
      >
        <Headline headerType="h6" headline={item.header} />

        {(item.bodytext || item.blockLinks.length > 0) && (
          <div className="mt-xsmall">
            {item.elementtype === "text" ? (
              <Text textType="html" text={item.bodytext} additionalClasses="block-items-text" />
            ) : (
              item.blockLinks.map((link) => {
                return (
                  <div key={`key_${link.link.url || Math.random()}`} className="block-items-link">
                    <a className="link link-primary" href={link.link.url}>
                      {link.link.title}
                    </a>
                  </div>
                );
              })
            )}
          </div>
        )}
      </div>
    );
  }

  // const renderFooterLinks = () => {
  //   return content.footerLinks?.map((footerLink) => {
  //     return (
  //       <a
  //         key={`key_${footerLink.link.url || Math.random()}`}
  //         className="link link-sm link-primary link-divider d-inline-block"
  //         href={footerLink.link.url}
  //       >
  //         {footerLink.link.title}
  //       </a>
  //     );
  //   });
  // };

  return (
    <div className="container footer contact">
      <>
        <div className="row justify-content-between mb-large">
          <div className="col-lg-3 col-xxl-2">
            <Image image={content.logo[0]} />

            <Headline headerType={content.headerLayout} headline={content.header} />

            {content.socialMedia.links.length > 0 && (
              <div className="mt-small">
                <Headline headerType="h6" headline={content.socialMedia.label} />
                <div>{renderSocialLinks()}</div>
              </div>
            )}

            {(content.address || content.phonenumber || content.headerLink) && (
              <div className="mt-small">{renderContactInfo()}</div>
            )}
          </div>

          <div className="col-lg-9 col-xxl-10 mt-5 mt-lg-0 ps-lg-5">
            {content.blockItems.length > 0 && (
              <>
                <div className="row block-items">
                  <Headline headerType="h6" headline={content.blockItemsLabel} />

                  {content.blockItems?.map((item, index) => {
                    return renderBlockItem(item, index);
                  })}
                </div>
              </>
            )}
          </div>
        </div>

        <div className="row mb-xxsmall">
          {/* <div className="col-12"> */}
          <DividerSwitcher layout="standard" />
          {/* </div> */}
        </div>
      </>

      <div className="row footerlinks justify-content-between">
        {/* <div className="col-md-4 order-2 order-md-1 text-center text-md-start mt-4 mt-md-0">
          <div className="teaser fs-7">{content.copyright}</div>
        </div>
        <div className="col-md-6 order-1 order-md-2 text-center text-md-end">
          {renderFooterLinks()}
        </div> */}

        <div className="col-12 text-center">
          <div className="teaser fs-7">{content.copyright}</div>
        </div>
      </div>

      {content.additionalImage?.[0] && (
        <div className="row additional-image mt-small">
          <div className="col-12">
            <Image image={content.additionalImage[0]} />
          </div>
        </div>
      )}
    </div>
  );
};

BlockFooter.propTypes = {
  content: PropTypes.instanceOf(Object),
};

export default BlockFooter;
