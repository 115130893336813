import React, { useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line no-unused-vars
import videojs from "video.js";
import VREPlayer from "videojs-react-enhanced";
// import AOS from "aos";

import Headline from "../../Atoms/Headline";
import Preline from "../../Atoms/Preline";

import "video.js/dist/video-js.css";

const StandardVideo = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    id,
    video,
    poster,
    cols,
    alignment,
    autoplayOnVisible,
  },
}) => {
  const getClasses = useCallback(() => {
    let classes = "row";

    switch (alignment) {
      case "right":
        classes += " justify-content-end";
        break;
      case "center":
        classes += " justify-content-center";
        break;
      default:
        classes += " justify-content-start";
    }

    return classes;
  }, [alignment]);

  const videoRef = useRef(null);

  useEffect(() => {
    if (autoplayOnVisible) {
      const revealHandler = (e) => {
        // console.log(e);
        if (e.detail.classList.contains("aos-animate")) {
          videoRef.current.muted(true);

          videoRef.current.play();
        }
      };

      // const hideHandler = (e) => {
      //   console.log(e);
      //   console.log("hide");

      //   videoRef.current.pause();
      //   videoRef.current.currentTime(0);
      //   videoRef.current.trigger("loadstart");
      // };

      document.addEventListener(`aos:in:video-${id}`, revealHandler);

      // AOS bugs: doesen't have an ID in event name and also triggers "in"
      // document.addEventListener(`aos:out`, hideHandler);
    }
  }, [autoplayOnVisible, id]);

  const playerOptions = {
    src: video ? video.url : "",
    controls: !autoplayOnVisible,
    autoplay: false,
    playsinline: true,
  };

  const resources = {
    poster: poster ? poster.publicUrl : "",
  };

  const videojsOptions = {
    fluid: true,
    playbackRates: [0.5, 1, 2],
  };

  return (
    <div className="container video standard">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />
        </div>
      </div>

      {video && (
        <div className="mt-small">
          <div className={getClasses()}>
            <div
              className={`col-md-${cols}`}
              data-aos-anchor-placement="top-center"
              data-aos-offset="100"
              data-aos="none"
              data-aos-once="false"
              // data-aos-mirror="true"
              data-aos-id={`video-${id}`}
            >
              <VREPlayer
                playerOptions={playerOptions}
                resources={resources}
                videojsOptions={videojsOptions}
                onReady={(player) => {
                  videoRef.current = player;

                  player.playsinline(true);
                  // AOS.refreshHard();
                }}
                onPlay={() => {}}
                onPause={() => {}}
                onEnded={() => {}}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

StandardVideo.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardVideo;
