import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { Parallax } from "react-scroll-parallax";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

import { isMobile } from "../../../Utils/FunctionalUtils";

const CenteredStage = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    media,
    maxHeight,
    viewportHeight,
    verticalAlignment,
    animation,
  },
}) => {
  const inlineStyles = {
    position: "absolute",
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: "0",
  };

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const setDisabledHandler = () => {
      if (isMobile()) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    };

    setDisabledHandler();

    window.addEventListener("resize", setDisabledHandler);
  }, []);

  const getClasses = useCallback(() => {
    let classes = "row justify-content-center text-start";

    switch (verticalAlignment) {
      case "top":
        classes += " align-items-start";
        break;
      case "center":
        classes += " align-items-center";
        break;
      case "bottom":
        classes += " align-items-end";
        break;
      default:
        classes += " align-items-center";
    }

    return classes;
  }, [verticalAlignment]);

  return (
    <div
      className={`stage centered ${
        viewportHeight ? "min-vh-100 pt-xlarge pb-xlarge" : "pt-xlarge pb-xlarge"
      }`.trim()}
      style={
        !viewportHeight ? { minHeight: +maxHeight || media[0]?.properties.dimensions.height } : {}
      }
    >
      <Image image={media[0]} style={inlineStyles} />

      <div className="container">
        {/* <div className="row justify-content-center align-items-center text-center"> */}
        <div className={getClasses()}>
          <div className="col-md-12">
            <Parallax y={[25, -50]} disabled={isDisabled}>
              {subline && (
                <div data-aos={animation.preline.default}>
                  <Preline preline={subline} />
                </div>
              )}

              {header && (
                <div data-aos={animation.headline.stage.centered}>
                  <Headline
                    headerType={headerType}
                    headerStyle={headerDisplayStyle}
                    headline={header}
                  />
                </div>
              )}

              {teaser && (
                <div className="mt-medium" data-aos={animation.teaser.default}>
                  <Text textType="html" text={teaser} />
                </div>
              )}

              {(headerLink || secondaryLink) && (
                <div className="mt-small" data-aos={animation.links.default}>
                  <div className="links">
                    <LinkSwitcher
                      linkType={headerLinkType}
                      link={headerLink}
                      // additionalClasses="btn-lg rounded-pill"
                    />

                    <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
                  </div>
                </div>
              )}
            </Parallax>
          </div>
        </div>
      </div>
    </div>
  );
};

CenteredStage.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default CenteredStage;
