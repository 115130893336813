import React from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line import/no-cycle
import StandardTransitionContainer from "./StandardTransitionContainer";
import ComponentMissing from "../../Debug/ComponentMissing";

const TransitionContainerSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  subline,
  transitionContainerContent,
  items,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    transitionContainerContent,
    items,
  };

  function switchTransitionContainer() {
    switch (layout) {
      case "standard":
        return <StandardTransitionContainer properties={properties} />;
      default:
        return <ComponentMissing type="TransitionContainer" subType={layout} />;
    }
  }

  return switchTransitionContainer();
};

TransitionContainerSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  subline: PropTypes.string,
  transitionContainerContent: PropTypes.instanceOf(Array),
  items: PropTypes.instanceOf(Object),
};

export default TransitionContainerSwitcher;
