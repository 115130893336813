/* eslint-disable max-len */
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { Parallax } from "react-scroll-parallax";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

import { isMobile } from "../../../Utils/FunctionalUtils";

const PromotionStage = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    media,
    logo,
    maxHeight,
    viewportHeight,
    mirrored,
    verticalAlignment,
    animation,
  },
}) => {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const setDisabledHandler = () => {
      if (isMobile()) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    };

    setDisabledHandler();

    window.addEventListener("resize", setDisabledHandler);
  }, []);

  const getClasses = useCallback(() => {
    let classes = "row flex-grow-1";

    switch (verticalAlignment) {
      case "top":
        classes += " ";
        break;
      case "center":
        classes += " ";
        break;
      case "bottom":
        classes += " ";
        break;
      default:
        classes += " ";
    }

    return classes;
  }, [verticalAlignment]);

  const getImageContainer = useCallback(() => {
    let inlineStyles = {
      position: "absolute",
      right: 0,
      top: 0,
      bottom: 0,
      width: "inherit",
      height: "100%",
      objectFit: "cover",
    };

    if (mirrored && mirrored !== "0") {
      inlineStyles = { ...inlineStyles, right: "auto", left: 0 };
    }

    return (
      <div className="col-lg-6 d-flex justify-content-center order-1 order-lg-2 mb-small mb-lg-0">
        <Image
          image={media[0]}
          style={inlineStyles}
          additionalClasses="stage-img"
          animation={animation.image.stage}
        />
      </div>
    );
  }, [media, mirrored, animation]);

  const getTextContainer = useCallback(() => {
    return (
      <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column">
        {logo && (
          <div className="stage-logo" data-aos="fade-up">
            <Image image={logo[0]} />
          </div>
        )}

        <div className="stage-text">
          <Parallax y={[20, -50]} disabled={isDisabled}>
            {subline && (
              <div data-aos={animation.preline.default}>
                <Preline preline={subline} />
              </div>
            )}

            {header && (
              <div data-aos={animation.headline.stage.promotion}>
                <Headline
                  headerType={headerType}
                  headerStyle={headerDisplayStyle}
                  headline={header}
                />
              </div>
            )}

            {teaser && (
              <div className="mt-xsmall">
                <Text textType="html" text={teaser} />
              </div>
            )}

            {(headerLink || secondaryLink) && (
              <div className="mt-small" data-aos={animation.links.default}>
                <div className="links">
                  <LinkSwitcher
                    linkType={headerLinkType}
                    link={headerLink}
                    // additionalClasses="btn-lg rounded-pill"
                  />

                  <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
                </div>
              </div>
            )}
          </Parallax>
        </div>
      </div>
    );
  }, [
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    logo,
    animation,
    isDisabled,
  ]);

  const getContent = useCallback(() => {
    if (mirrored && mirrored !== "0") {
      return (
        <div className={`${getClasses()} flex-row-reverse mirrored`}>
          {getTextContainer()}
          {getImageContainer()}
        </div>
      );
    }

    return (
      <div className={getClasses()}>
        {getTextContainer()}
        {getImageContainer()}
      </div>
    );
  }, [mirrored, getImageContainer, getTextContainer, getClasses]);

  return (
    <div
      className={`container stage promotion d-flex ${viewportHeight ? "min-vh-100" : ""}`.trim()}
      style={
        !viewportHeight ? { minHeight: +maxHeight || media[0]?.properties.dimensions.height } : {}
      }
    >
      {getContent()}
    </div>
  );
};

PromotionStage.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default PromotionStage;
