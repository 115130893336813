import React from "react";
import PropTypes from "prop-types";

import Headline from "../../Atoms/Headline";
import Preline from "../../Atoms/Preline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";
import SecondaryTAILItem from "./SecondaryTAILItem";

const SecondaryTAIL = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    iconList,
    viewportHeight,
    animation,
  },
}) => {
  return (
    <div
      className={`container text-and-icon-list secondary pt-medium pb-medium ${
        viewportHeight ? "min-vh-100 d-flex flex-column justify-content-center" : ""
      }`.trim()}
    >
      <div className="row justify-content-center align-items-center">
        <div className="col-10 col-lg-4 mb-4 mb-lg-0">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="html" text={teaser} />
            </div>
          )}

          {(headerLink || secondaryLink) && (
            <div className="mt-small d-none d-lg-block" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  // additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </div>

        <div className="col-10 col-lg-6">
          {iconList?.map((item, index) => {
            return (
              <SecondaryTAILItem
                key={`key_${item.header || Math.random()}`}
                headline={item.header}
                image={item.image[0]}
                teaser={item.teaser}
                index={index}
                length={iconList.length}
              />
            );
          })}

          {(headerLink || secondaryLink) && (
            <div className="mt-small d-lg-none" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  // additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SecondaryTAIL.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default SecondaryTAIL;
