import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const CardTAI = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    image,
    backgroundImage,
    maxHeight,
    viewportHeight,
    mirrored,
    fixedImage,
    animation,
  },
}) => {
  const inlineStyles = {
    position: "absolute",
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: "-1",
  };

  const getImageContainer = useCallback(() => {
    return (
      <div className="col-lg-6 order-1 order-lg-0 mb-4 mb-lg-0">
        <Image image={image} animation={animation.image.teaserAndImage} />
      </div>
    );
  }, [image, animation]);

  const getTextContainer = useCallback(() => {
    return (
      <div className="col-lg-6 order-2 order-lg-0">
        <div className="card">
          <div className="card-body">
            <Preline preline={subline} additionalClasses="color-static" />

            <Headline
              headerType={headerType}
              headerStyle={headerDisplayStyle}
              headline={header}
              additionalClasses="color-static"
            />

            {teaser && (
              <div className="mt-xsmall">
                <Text textType="html" text={teaser} additionalClasses="color-static" />
              </div>
            )}

            {(headerLink || secondaryLink) && (
              <div className="mt-small" data-aos={animation.links.default}>
                <div className="links">
                  <LinkSwitcher
                    linkType={headerLinkType}
                    link={headerLink}
                    // additionalClasses="btn-lg rounded-pill"
                  />

                  <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }, [
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    animation,
  ]);

  const getContent = useCallback(() => {
    if (mirrored && mirrored !== "0") {
      return (
        <div className="row flex-row-reverse align-items-center mirrored">
          {getImageContainer()}
          {getTextContainer()}
        </div>
      );
    }

    return (
      <div className="row align-items-center">
        {getImageContainer()}
        {getTextContainer()}
      </div>
    );
  }, [mirrored, getImageContainer, getTextContainer]);

  return (
    <div
      className={`teaser-and-image card-tai ${fixedImage ? "fixed" : ""} ${
        viewportHeight
          ? "min-vh-100 d-flex flex-column justify-content-center pt-xlarge pb-xlarge"
          : ""
      }`.trim()}
      style={{ minHeight: +maxHeight || backgroundImage?.properties.dimensions.height }}
    >
      <Image image={backgroundImage} style={inlineStyles} />

      <div className="container">{getContent()}</div>
    </div>
  );
};

CardTAI.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default CardTAI;
