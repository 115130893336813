import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const AlternatingTimeline = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    timeline,
    animation,
  },
}) => {
  useEffect(() => {
    // let animatedAmount = -1;

    const revealHandler = (e) => {
      // animatedAmount += 1;

      // setTimeout(() => {
      //   e.detail.addEventListener("transitionend", (event) => {
      //     if (event.propertyName === "height") {
      //       animatedAmount -= 1;
      //     }
      //   });

      //   e.detail.classList.add("timeline-animated");
      // }, animatedAmount * 5000);

      e.detail.classList.add("timeline-animated");
    };

    document.addEventListener("aos:in:timeline", revealHandler);

    return () => {
      document.removeEventListener("aos:in:timeline", revealHandler);
    };
  }, []);

  const renderTimeline = useCallback(() => {
    let alternateDirection = true;

    return timeline.map((date) => {
      alternateDirection = !alternateDirection;

      return (
        <React.Fragment key={Math.random()}>
          {date.sectionLabel && (
            <div className="timeline-label" data-aos="none" data-aos-id="timeline">
              <span>{date.sectionLabel}</span>
            </div>
          )}

          <div
            className={`timeline-card ${
              alternateDirection ? "timeline-card-alternate" : ""
            }`.trim()}
            data-aos="none"
            data-aos-id="timeline"
          >
            <div className="timeline-card-content">
              {date.image[0] && (
                <div className="timeline-image">
                  <Image image={date.image[0]} />
                </div>
              )}

              <div className="card">
                <div className="card-body">
                  {date.preline && (
                    <div className="mb-xxsmall">
                      <h6 className="timeline-date color-static">{date.preline}</h6>
                    </div>
                  )}

                  {date.header && (
                    <div className="mb-xxsmall">
                      <Headline
                        headerType="h6"
                        headline={date.header}
                        additionalClasses="color-static"
                      />
                    </div>
                  )}

                  <Text textType="html" text={date.contentText} additionalClasses="color-static" />

                  {date.link && (
                    <div className="mt-small" data-aos={animation.links.default}>
                      <div className="links">
                        <LinkSwitcher linkType={date.link_type} link={date.link} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    });
  }, [timeline, animation]);

  return (
    <div className="container timeline alternating">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="text" text={teaser} />
            </div>
          )}

          {(headerLink || secondaryLink) && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  // additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </div>
      </div>

      {timeline.length > 0 && (
        <div className="mt-small">
          <div className="row justify-content-center">
            <div className="timeline-content">
              <div className="timeline-line" />

              <div className="timeline-cards">{renderTimeline()}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

AlternatingTimeline.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default AlternatingTimeline;
