import React from "react";
import PropTypes from "prop-types";

import Headline from "../../Atoms/Headline";
import Preline from "../../Atoms/Preline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";
import IconWallTAILItem from "./IconWallTAILItem";

const IconWallTAIL = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    iconList,
    viewportHeight,
    animation,
  },
}) => {
  return (
    <div
      className={`container text-and-icon-list icon-wall pt-medium pb-medium ${
        viewportHeight ? "min-vh-100 d-flex flex-column justify-content-center" : ""
      }`.trim()}
    >
      <div className="row justify-content-center">
        <div className="col-10 col-lg-4 mb-4 mb-lg-0">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="html" text={teaser} />
            </div>
          )}

          {(headerLink || secondaryLink) && (
            <div className="mt-small d-none d-lg-block" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  // additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </div>

        <div className="col-10 col-lg-6">
          <div className="row row-cols-1 row-cols-md-2 g-4">
            {iconList?.map((item, index) => {
              return (
                <IconWallTAILItem
                  key={`key_${item.header || Math.random()}`}
                  headline={item.header}
                  image={item.image[0]}
                  teaser={item.teaser}
                  index={index}
                  length={iconList.length}
                />
              );
            })}

            {(headerLink || secondaryLink) && (
              <div className="mt-small d-lg-none" data-aos={animation.links.default}>
                <div className="links">
                  <LinkSwitcher
                    linkType={headerLinkType}
                    link={headerLink}
                    // additionalClasses="btn-lg rounded-pill"
                  />

                  <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

IconWallTAIL.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default IconWallTAIL;
