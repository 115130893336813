import React from "react";
import PropTypes from "prop-types";

import ComponentMissing from "../Debug/ComponentMissing";
import SimpleFooter from "./SimpleFooter";
import BlockFooter from "./BlockFooter";
import ContactFooter from "./ContactFooter";
import GoogleMapsSwitcher from "../ContentElements/GoogleMaps/GoogleMapsSwitcher";
import FactSheetSwitcher from "../ContentElements/FactSheet/FactSheetSwitcher";
import FeaturesSwitcher from "../ContentElements/Features/FeaturesSwitcher";
import BuyFondsModalSwitcher from "../ContentElements/BuyFondsModal/BuyFondsModalSwitcher";
import ContactInfoSwitcher from "../ContentElements/ContactInfo/ContactInfoSwitcher";
// eslint-disable-next-line max-len
import GoogleMapsAndContactsSwitcher from "../ContentElements/GoogleMapsAndContacts/GoogleMapsAndContactsSwitcher";
import LoginModalSwitcher from "../ContentElements/LoginModal/LoginModalSwitcher";

import { getAnimation } from "../../Utils/ElementUtils";

const FooterSwitcher = ({ footer }) => {
  const getClasses = (baseClass, appearance) => {
    let classes = `${baseClass} ${appearance.frameClass}`;
    classes += appearance.bgColorClass.length ? ` ${appearance.bgColorClass}` : "";
    classes += appearance.spaceBefore.length ? ` ${appearance.spaceBefore}` : "";
    classes += appearance.spaceAfter.length ? ` ${appearance.spaceAfter}` : "";
    classes += appearance.centerheads === "1" ? " centered" : "";

    return classes;
  };

  function switchFooter(item) {
    switch (item.type) {
      case "ahoii_toolkit_simple_footer":
        return (
          <section
            key={`key_${item.id}`}
            id="footer"
            className={getClasses("footer", item.appearance)}
          >
            <SimpleFooter content={item.content} />
          </section>
        );
      case "ahoii_toolkit_block_footer":
        return (
          <section
            key={`key_${item.id}`}
            id="footer"
            className={getClasses("footer", item.appearance)}
          >
            <BlockFooter content={item.content} />
          </section>
        );
      case "tx_ahoii_toolkit_contact_footer":
        return (
          <section
            key={`key_${item.id}`}
            id="footer"
            className={getClasses("footer", item.appearance)}
          >
            <ContactFooter content={item.content} />
          </section>
        );
      case "ahoii_toolkit_google_maps":
        return (
          <section
            key={`key_${item.id}`}
            id="pre-footer"
            className={getClasses("p-0", item.appearance)}
          >
            <GoogleMapsSwitcher
              layout={item.appearance.layout}
              header={item.content.header}
              headerType={item.content.headerLayout}
              subline={item.content.subheader}
              mapStyle={item.content.mapStyle}
              icon={item.content.marker[0]}
              markers={item.content.items}
              centerLat={item.content.centerLat}
              centerLng={item.content.centerLng}
              animation={getAnimation(item.appearance.animationLayout)}
            />
          </section>
        );
      case "ahoii_toolkit_features":
        return (
          <section
            key={`key_${item.id}`}
            id="pre-footer"
            className={getClasses("p-0", item.appearance)}
          >
            <FeaturesSwitcher
              layout={item.appearance.layout}
              header={item.content.header}
              headerType={item.content.headerLayout}
              headerDisplayStyle={item.content.headerDisplayStyle}
              subline={item.content.subheader}
              id={item.id}
              features={item.content.items}
            />
          </section>
        );
      case "ahoii_toolkit_factsheet":
        return (
          <section
            key={`key_${item.id}`}
            id="pre-footer"
            className={getClasses("footer_info", item.appearance)}
          >
            <FactSheetSwitcher
              layout={item.appearance.layout}
              header={item.content.header}
              headerType={item.content.headerLayout}
              headerDisplayStyle={item.content.headerDisplayStyle}
              subline={item.content.subheader}
              teaser={item.content.teaser}
              facts={item.content.facts}
              columnsPerRow={item.appearance.columnsPerRow}
              cols={item.appearance.cols}
              alignment={item.appearance.alignment}
            />
          </section>
        );
      case "easyfolio_buy_fonds_modal":
        return (
          <section
            key={`key_${item.id}`}
            id="pre-footer"
            className={getClasses("p-0", item.appearance)}
          >
            <BuyFondsModalSwitcher
              layout={item.appearance.layout}
              sectionId={item.content.sectionId}
              fonds={item.content.fonds}
            />
          </section>
        );
      case "ahoii_toolkit_sticky_contact":
        return (
          <section
            key={`key_${item.id}`}
            id="pre-footer"
            className={getClasses("p-0", item.appearance)}
          >
            <ContactInfoSwitcher layout={item.appearance.layout} contactInfo={item.content.items} />
          </section>
        );
      case "ahoii_toolkit_google_maps_and_contact":
        return (
          <section
            key={`key_${item.id}`}
            id="pre-footer"
            className={getClasses("p-0", item.appearance)}
          >
            <GoogleMapsAndContactsSwitcher
              layout={item.appearance.layout}
              header={item.content.header}
              headerType={item.content.headerLayout}
              headerDisplayStyle={item.content.headerDisplayStyle}
              subline={item.content.subheader}
              contactTitle={item.content.contactTitle}
              contactAddress={item.content.contactAddress}
              contactPhone={item.content.contactPhone}
              contactPhoneAlt={item.content.contactPhoneAlt}
              contactEmail={item.content.contactEmail}
              contactLink={item.content.contactLink}
              mirrored={item.appearance.mirrored}
              mapStyle={item.content.mapStyle}
              icon={item.content.items[0]}
              markers={item.content.items}
              centerLat={item.content.centerLat}
              centerLng={item.content.centerLng}
              defaultZoom={item.content.defaultZoom}
            />
          </section>
        );
      case "ahoii_toolkit_login_modal":
        return (
          <section
            key={`key_${item.id}`}
            id="pre-footer"
            className={getClasses("p-0", item.appearance)}
          >
            <LoginModalSwitcher
              layout={item.appearance.layout}
              header={item.content.header}
              headerType={item.content.headerLayout}
              headerDisplayStyle={item.content.headerDisplayStyle}
              formLink={item.content.posturl}
              descriptionLink={item.content.helpurl}
              descriptionBackground={item.content.media[0]}
              descriptionImage={item.content.logo[0]}
              descriptionHeader={item.content.description}
              descriptionText={item.content.teaser}
            />
          </section>
        );
      default:
        return <ComponentMissing type="Footer" subType={item.type} />;
    }
  }

  return footer?.map((item) => {
    return switchFooter(item);
  });
};

FooterSwitcher.propTypes = {
  footer: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string]),
};

export default FooterSwitcher;
