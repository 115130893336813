import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { Parallax } from "react-scroll-parallax";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

import { isMobile } from "../../../Utils/FunctionalUtils";

const SmallTAI = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    image,
    backgroundImage,
    maxHeight,
    viewportHeight,
    mirrored,
    fixedImage,
    animation,
  },
}) => {
  const inlineStyles = {
    position: "absolute",
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: "-1",
  };

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const setDisabledHandler = () => {
      if (isMobile()) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    };

    setDisabledHandler();

    window.addEventListener("resize", setDisabledHandler);
  }, []);

  const getImageContainer = useCallback(() => {
    return (
      <div className="col-lg-4 order-1 order-lg-0 mb-4 mb-lg-0">
        <Parallax y={["-125px", "125px"]} disabled={isDisabled}>
          <Image image={image} animation={animation.image.teaserAndImage} />
        </Parallax>
      </div>
    );
  }, [image, animation, isDisabled]);

  const getTextContainer = useCallback(() => {
    return (
      <div className="col-lg-8 order-2 order-lg-0">
        <Parallax y={[-15, 15]} disabled={isDisabled}>
          {subline && (
            <div data-aos={animation.preline.default}>
              <Preline preline={subline} />
            </div>
          )}

          {header && (
            <div data-aos={animation.headline.default}>
              <Headline
                headerType={headerType}
                headerStyle={headerDisplayStyle}
                headline={header}
              />
            </div>
          )}

          {teaser && (
            <div className="mt-xsmall" data-aos={animation.teaser.default}>
              <Text textType="html" text={teaser} />
            </div>
          )}

          {(headerLink || secondaryLink) && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  // additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </Parallax>
      </div>
    );
  }, [
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    animation,
    isDisabled,
  ]);

  const getContent = useCallback(() => {
    if (mirrored && mirrored !== "0") {
      return (
        <div className="row flex-row-reverse align-items-center mirrored">
          {getImageContainer()}
          {getTextContainer()}
        </div>
      );
    }

    return (
      <div className="row align-items-center">
        {getImageContainer()}
        {getTextContainer()}
      </div>
    );
  }, [mirrored, getImageContainer, getTextContainer]);

  return (
    <div
      className={`teaser-and-image small ${fixedImage ? "fixed" : ""} ${
        viewportHeight
          ? "min-vh-100 d-flex flex-column justify-content-center pt-xlarge pb-xlarge"
          : ""
      }`.trim()}
      style={{ minHeight: +maxHeight || backgroundImage?.properties.dimensions.height }}
    >
      <Image image={backgroundImage} style={inlineStyles} />

      <div className="container">{getContent()}</div>
    </div>
  );
};

SmallTAI.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default SmallTAI;
