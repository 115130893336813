import React from "react";
import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const CardListNewsListItem = ({ news, linkType, linkTitle, hideDate, animation }) => {
  const inlineStyles = {};

  return (
    <div className="col d-flex" data-aos={animation.newsList.default}>
      <div className="card w-100">
        <div className="card-body">
          {news.media?.[0]?.images.listViewImage && (
            <div className="card-image">
              <Image
                image={{
                  publicUrl: news.media?.[0]?.images.listViewImage.publicUrl,
                  properties: { alternative: news.media?.[0]?.properties.alternative },
                }}
                style={inlineStyles}
              />
            </div>
          )}

          <div className="news-list-content">
            {news.slug &&
              (news.slug.includes("http") ? (
                <a href={news.slug}>
                  <Headline
                    headerType="h4"
                    headline={news.title}
                    additionalClasses="color-static"
                  />
                </a>
              ) : (
                <NavLink to={news.slug}>
                  <Headline
                    headerType="h4"
                    headline={news.title}
                    additionalClasses="color-static"
                  />
                </NavLink>
              ))}

            <div className="news-list-info">
              {!hideDate && <h6 className="news-list-date color-static">{news.datetime}</h6>}

              <Text
                textType="text"
                text={`${news.teaser.slice(0, 350)}...`}
                additionalClasses="color-static"
              />
            </div>
          </div>

          <div className="news-list-link">
            {news.slug && (
              // <div data-aos={animation.links.default}>
              <div>
                <div className="links">
                  <LinkSwitcher
                    linkType={linkType}
                    link={{ publicUrl: news.slug, title: linkTitle }}
                    additionalClasses="color-static"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CardListNewsListItem.propTypes = {
  news: PropTypes.instanceOf(Object),
  linkType: PropTypes.string,
  linkTitle: PropTypes.string,
  hideDate: PropTypes.bool,
  animation: PropTypes.instanceOf(Object),
};

export default CardListNewsListItem;
