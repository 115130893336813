import React from "react";
import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";
import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const ThumbnailTeaserNewsListItem = ({ news, linkType, linkTitle, hideDate, animation }) => {
  const inlineStyles = {};

  return (
    <div className="col d-flex" data-aos={animation.newsList.default}>
      <div className="card w-100">
        <div className="news-list-content">
          {news.media?.[0]?.images.listViewImage && (
            <div className="card-image">
              <Image
                image={{
                  publicUrl: news.media?.[0]?.images.listViewImage.publicUrl,
                  properties: { alternative: news.media?.[0]?.properties.alternative },
                }}
                style={inlineStyles}
              />
            </div>
          )}

          <div className="news-list-info">
            {!hideDate && <Preline preline={news.datetime} />}

            {news.slug &&
              (news.slug.includes("http") ? (
                <a href={news.slug}>
                  <Headline
                    headerType="h4"
                    headline={news.title}
                    additionalClasses="color-static"
                  />
                </a>
              ) : (
                <NavLink to={news.slug}>
                  <Headline
                    headerType="h4"
                    headline={news.title}
                    additionalClasses="color-static"
                  />
                </NavLink>
              ))}
          </div>
        </div>

        {news.teaser && (
          <div className="mt-small">
            <Text textType="text" text={`${news.teaser.slice(0, 350)}...`} />
          </div>
        )}

        {news.slug && (
          // <div className="mt-small" data-aos={animation.links.default}>
          <div className="mt-small">
            <div className="links">
              <LinkSwitcher linkType={linkType} link={{ publicUrl: news.slug, title: linkTitle }} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ThumbnailTeaserNewsListItem.propTypes = {
  news: PropTypes.instanceOf(Object),
  linkType: PropTypes.string,
  linkTitle: PropTypes.string,
  hideDate: PropTypes.bool,
  animation: PropTypes.instanceOf(Object),
};

export default ThumbnailTeaserNewsListItem;
