// eslint-disable-next-line import/prefer-default-export
export const debounce = (callback, wait) => {
  let timeoutId = null;

  return (...args) => {
    window.clearTimeout(timeoutId);

    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

export const randomString = (len) => {
  const dec2hex = (dec) => {
    return dec.toString(16).padStart(2, "0");
  };

  const arr = new Uint8Array((len || 40) / 2);

  (window.crypto || window.msCrypto).getRandomValues(arr);

  return Array.from(arr, dec2hex).join("");
};

export const generateId = (element) => {
  const umlautMap = {
    "\u00fc": "ue",
    "\u00e4": "ae",
    "\u00f6": "oe",
    "\u00df": "ss",
  };

  const headerText = element.content?.header || element.header;

  const text = headerText
    ? headerText
        .toLowerCase()
        .replace(/\s+/g, "_")
        .replace(new RegExp(`[${Object.keys(umlautMap).join("|")}]`, "g"), (a) => umlautMap[a])
        .replace(/[-.,;!?]/g, "")
    : randomString(6);

  const id = element.id || element.uid;

  const elementId = `${text}_${id}`;

  return elementId;
};

export const validate = (value, validator) => {
  switch (validator) {
    case "NotEmpty":
      return !value && "Required field";
    case "EmailAddress":
      return (
        !value.match(
          // eslint-disable-next-line max-len
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ) && "Incorrect email"
      );
    default:
      return false;
  }
};

export const isMobile = () => {
  return (
    /Android|webOS|iPhone|iPad|iPod|MacIntel|BlackBerry/i.test(navigator.userAgent) ||
    /Android|webOS|iPhone|iPad|iPod|MacIntel|BlackBerry/i.test(navigator.platform) ||
    (navigator.maxTouchPoints && navigator.maxTouchPoints > 2)
  );
};

export const isInViewport = (element, offset = 0) => {
  if (element) {
    const rect = element.getBoundingClientRect();

    return (
      rect.top > 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight) - offset
    );
  }

  return false;
};

export const parseCookies = (cookies) => {
  if (!cookies) {
    return {};
  }

  const cookieString = cookies.replaceAll("'", '"').replace(/(\w+:)|(\w+ :)/g, function (s) {
    return `"${s.substring(0, s.length - 1)}":`;
  });

  return JSON.parse(cookieString);
};
