import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const HorizontalHeader = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    cols,
    alignment,
    colProportions,
    viewportHeight,
    mirrored,
    animation,
  },
}) => {
  const proportion = useRef(colProportions ? colProportions.split("-") : [6, 6]);

  const getClasses = useCallback(() => {
    let classes = "row";

    switch (alignment) {
      case "right":
        classes += " justify-content-end";
        break;
      case "center":
        classes += " justify-content-center";
        break;
      default:
        classes += " justify-content-start";
    }

    return classes;
  }, [alignment]);

  const getTextContainer = useCallback(
    (proportionIndex) => {
      return (
        <div className={`col-md-${proportion.current[proportionIndex]}`}>
          {subline && (
            <div data-aos={animation.preline.default}>
              <Preline preline={subline} />
            </div>
          )}

          {header && (
            <div data-aos={animation.headline.default}>
              <Headline
                headerType={headerType}
                headerStyle={headerDisplayStyle}
                headline={header}
              />
            </div>
          )}

          {teaser && (
            <div className="mt-xsmall" data-aos={animation.teaser.default}>
              <Text textType="html" text={teaser} />
            </div>
          )}
        </div>
      );
    },
    [header, headerType, headerDisplayStyle, subline, teaser, animation, proportion],
  );

  const getLinksContainer = useCallback(
    (proportionIndex) => {
      return (
        <div className={`col-md-${proportion.current[proportionIndex]} text-center`}>
          {(headerLink || secondaryLink) && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  // additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </div>
      );
    },
    [headerLink, headerLinkType, secondaryLink, secondaryLinkType, animation, proportion],
  );

  const getContent = useCallback(() => {
    if (mirrored && mirrored !== "0") {
      return (
        <div className="row flex-row-reverse mirrored">
          {getTextContainer(1)}
          {getLinksContainer(0)}
        </div>
      );
    }

    return (
      <div className="row">
        {getTextContainer(0)}
        {getLinksContainer(1)}
      </div>
    );
  }, [mirrored, getTextContainer, getLinksContainer]);

  return (
    <div
      className={`container header horizontal ${
        viewportHeight
          ? "min-vh-100 d-flex flex-column justify-content-center pt-small pb-small"
          : ""
      }`.trim()}
    >
      <div className={getClasses()}>
        <div className={`col-md-${cols}`}>{getContent()}</div>
      </div>
    </div>
  );
};

HorizontalHeader.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default HorizontalHeader;
