import React from "react";
import PropTypes from "prop-types";

import StandardMenuSubPagesFullWidth from "./StandardMenuSubPagesFullWidth";
import ComponentMissing from "../../Debug/ComponentMissing";

const MenuSubPagesFullWidthSwitcher = ({
  properties: {
    layout,
    primaryLink,
    primaryLinkType,
    secondaryLink,
    secondaryLinkType,
    logo,
    topLinks,
    navigation,
    languages,
    activeLanguage,
  },
}) => {
  const properties = {
    primaryLink,
    primaryLinkType,
    secondaryLink,
    secondaryLinkType,
    logo,
    topLinks,
    navigation,
    languages,
    activeLanguage,
  };

  function switchMenuSubPagesFullWidth() {
    switch (layout) {
      case "standard":
        return <StandardMenuSubPagesFullWidth properties={properties} />;
      default:
        return <ComponentMissing type="MenuSubPagesFullWidth" subType={layout} />;
    }
  }

  return switchMenuSubPagesFullWidth();
};

MenuSubPagesFullWidthSwitcher.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default MenuSubPagesFullWidthSwitcher;
