import React, { useEffect, useRef, useReducer } from "react";
import PropTypes from "prop-types";

import { Controller, Scene } from "react-scrollmagic";

// import Preline from "../../Atoms/Preline";
// import Headline from "../../Atoms/Headline";

// eslint-disable-next-line import/no-cycle
import ElementSwitcher from "../ElementSwitcher";

import { debounce } from "../../../Utils/FunctionalUtils";

const StandardTransitionContainer = ({
  properties: {
    // header,
    // headerType,
    // headerDisplayStyle,
    // subline,
    // items,
    transitionContainerContent,
  },
}) => {
  const controllerRef = useRef(null);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    let windowWidth = window.innerWidth;
    // window.innerHeight doesn't work right on IOS
    let windowHeight = document.documentElement.clientHeight;

    const onResize = debounce(() => {
      // // recalculating positions works only this way
      // controllerRef.current.state.controller.enabled(false);

      // controllerRef.current.state.controller.update(true);

      // ended up using a trick with Math.random() key

      // added width check as iOS triggers resize on scroll!!!

      if (window.innerWidth !== windowWidth) {
        windowWidth = window.innerWidth;

        forceUpdate();
      }

      if (document.documentElement.clientHeight !== windowHeight) {
        windowHeight = document.documentElement.clientHeight;

        forceUpdate();
      }

      // controllerRef.current.state.controller.enabled(true);
    }, 250);

    window.addEventListener("resize", onResize);

    // adding a small delay to make animation fire a bit later
    const aos = document.querySelectorAll(".transition-container.standard [data-aos]");

    aos.forEach((animatedElement) => {
      animatedElement.setAttribute("data-aos-delay", 250);
    });

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <div className="transition-container standard" key={Math.random()}>
      <Controller ref={controllerRef}>
        {transitionContainerContent.map((transitionElement, index) => {
          return (
            <Scene
              pin
              triggerHook="onLeave"
              enabled={index !== transitionContainerContent.length - 1}
              key={transitionElement.id}
            >
              <div>
                <ElementSwitcher contentElements={[transitionElement]} isTabCycle />
              </div>
            </Scene>
          );
        })}
      </Controller>
    </div>
  );
};

StandardTransitionContainer.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardTransitionContainer;
