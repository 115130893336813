import React from "react";
import PropTypes from "prop-types";

import StandardNewsList from "./StandardNewsList";
import VerticalNewsList from "./VerticalNewsList";
import MasonryNewsList from "./MasonryNewsList";
import WallNewsList from "./WallNewsList";
import CardListNewsList from "./CardListNewsList";
import ThumbnailTeaserNewsList from "./ThumbnailTeaserNewsList";
// eslint-disable-next-line import/no-cycle
import StandardNewsDetails from "./StandardNewsDetails";
// eslint-disable-next-line import/no-cycle
import SidebarNewsDetails from "./SidebarNewsDetails";
import SliderNewsList from "./SliderNewsList";
import ComponentMissing from "../../Debug/ComponentMissing";

const News = ({ layout, data, columnsPerRow, sidebar, animation }) => {
  let properties;

  function switchNews() {
    if (data.settings.action === "list") {
      properties = {
        news: data.list,
        columnsPerRow,
        hideDate: !!+data.settings.hideDate, // a dirty trick to convert "0" to boolean false
        animation,
      };

      switch (layout) {
        case "standard":
          return <StandardNewsList properties={properties} />;
        case "vertical":
          return <VerticalNewsList properties={properties} />;
        case "masonry":
          return <MasonryNewsList properties={properties} />;
        case "wall":
          return <WallNewsList properties={properties} />;
        case "cardList":
          return <CardListNewsList properties={properties} />;
        case "thumbnail":
          return <ThumbnailTeaserNewsList properties={properties} />;
        case "slider":
          return <SliderNewsList properties={properties} />;
        default:
          return <ComponentMissing type="NewsList" subType={layout} />;
      }
    }

    if (data.settings.action === "detail") {
      properties = {
        details: data.detail,
        contentElements: data.contentElements,
        prevArticle: data.settings.prevArticle,
        nextArticle: data.settings.nextArticle,
        relatedArticles: data.settings.relatedNews,
        hideDefaultHeader: data.settings.hideDefaultHeader,
        hideDate: !!+data.settings.hideDate, // a dirty trick to convert "0" to boolean false
        sidebar,
        animation,
      };

      switch (layout) {
        case "standard":
          return <StandardNewsDetails properties={properties} />;
        case "blog":
          return <SidebarNewsDetails properties={properties} />;
        default:
          return <ComponentMissing type="NewsDetails" subType={layout} />;
      }
    }

    return <></>;
  }

  return switchNews();
};

News.propTypes = {
  layout: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  columnsPerRow: PropTypes.instanceOf(Object),
  sidebar: PropTypes.instanceOf(Array),
  animation: PropTypes.instanceOf(Object),
};

export default News;
