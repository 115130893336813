import React from "react";
import PropTypes from "prop-types";

import StandardText from "./StandardText";
import TwoColumnsText from "./TwoColumnsText";
import CardText from "./CardText";
import HorizontalText from "./HorizontalText";
import ComponentMissing from "../../Debug/ComponentMissing";

const TextSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  headerLink,
  headerLinkType,
  secondaryLink,
  secondaryLinkType,
  subline,
  bodytext,
  cols,
  alignment,
  colProportions,
  viewportHeight,
  mirrored,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    bodytext,
    cols,
    alignment,
    colProportions,
    viewportHeight,
    mirrored,
    animation,
  };

  function switchText() {
    switch (layout) {
      case "standard":
        return <StandardText properties={properties} />;
      case "twoColumns":
        return <TwoColumnsText properties={properties} />;
      case "card":
        return <CardText properties={properties} />;
      case "horizontal":
        return <HorizontalText properties={properties} />;
      default:
        return <ComponentMissing type="Text" subType={layout} />;
    }
  }

  return switchText();
};

TextSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  headerLinkType: PropTypes.string,
  secondaryLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  secondaryLinkType: PropTypes.string,
  subline: PropTypes.string,
  bodytext: PropTypes.string,
  cols: PropTypes.string,
  alignment: PropTypes.string,
  colProportions: PropTypes.string,
  viewportHeight: PropTypes.bool,
  mirrored: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  animation: PropTypes.instanceOf(Object),
};

export default TextSwitcher;
